<template>
    <b-card>
        <b-row>
            <b-col sm="12">
                <h1>{{ i18nT(`Subscriptions and billing`) }}</h1>
                <p>{{ i18nT(`Subscription plan overview, billing information and invoices.`) }}</p>
            </b-col>
        </b-row>

        <!-- HAS ACTIVE PLAN -->
        <b-row v-if="subscriptionStatus.subscription_status === 'active'" >
            <b-col sm="12">
                <b-list-group-item tag="li" class="sk-list-group-item py-1">
                    <div class="">
                        <b-row>
                            <b-col cols="8">
                                <div class="sk-toggles d-flex align-items-center">
                                    <span class="mr-1">{{i18nT(`Current plan`)}}: </span>
                                    <strong>{{currentPlan.Label}} {{currentPlan.Price | currency}} / {{i18nT(billingModePeriod)}}</strong>
                                    <span
                                        class="ml-2"
                                        v-if="currentPlan.ExpiresAt">
                                        ( {{i18nT(`Renews on: `)}}
                                        {{currentPlan.ExpiresAt | date}} )
                                    </span>
                                </div>
                            </b-col>
                            <b-col cols="4" class="text-right">
                                <b-button
                                    v-if="currentPlan.Active"
                                    variant="outline-primary"
                                    class="mr-1"
                                    v-b-modal.modal-change-plan
                                >
                                    {{i18nT(`Modify`)}}
                                </b-button>
                            </b-col>
                        </b-row>
                    </div>
                </b-list-group-item>

                <b-list-group-item tag="li" class="sk-list-group-item">
                    <b-row>
                        <b-col cols="8">
                            <div class="sk-toggles d-flex align-items-center">
                                <font-awesome-icon
                                    class="sk-provider-icon mr-1"
                                    :icon="['fab', planPaymentMethod.icon]"
                                    :size="`2x`"
                                >
                                </font-awesome-icon>
                                <strong class="mr-1">{{ planPaymentMethod.method.card.brand}} ••• ••• {{ planPaymentMethod.method.card.last4}} </strong>
                                <span> Exp: {{planPaymentMethod.method.card.exp_month}}/{{planPaymentMethod.method.card.exp_year}}</span>
                            </div>
                        </b-col>
                        <b-col cols="4" class="text-right">
                            <b-button
                                v-if="currentPlan.Active"
                                variant="primary"
                                class="mr-1"
                                @click="openUpdatePaymentMethod"
                            >
                                {{i18nT(`Update`)}}
                            </b-button>
                        </b-col>
                    </b-row>

                </b-list-group-item>
            </b-col>
        </b-row>

        <!-- Invoice list -->
        <b-row class="mt-2" v-if="subscriptionStatus.subscription_status === 'active'">
            <b-col sm="12">
                <h3>{{ i18nT(`Invoice history`) }}</h3>
                <p>{{ i18nT(`You may download the invoice for your past transactions below.`) }}</p>
            </b-col>
            <b-col sm="12">
                <b-table
                    :items="billingInvoices"
                    :fields="tableFields"
                    :per-page="12"
                    :current-page="tableCurrentPage"
                    responsive
                    hover
                    striped

                    class="sk-table">
                    <template #cell(InvoiceNumber)="row">
                        <span>{{row.item.number}}</span>
                    </template>
                    <template #cell(InvoiceDate)="row">
                        <span>{{row.item.date | date}}</span>
                    </template>
                    <template #cell(InvoiceAmount)="row">
                        <span>{{row.item.price | currency}}</span>
                    </template>
                    <template #cell(InvoiceDescription)="row">
                        <span>{{i18nT(row.item.description)}}</span>
                    </template>
                    <template #cell(InvoicePeriod)="row">
                        <span>{{row.item.periodStart | date}} - {{row.item.periodEnd | date}}</span>
                    </template>
                </b-table>
            </b-col>
        </b-row>

        <!-- NO ACTIVE PLAN -->
        <b-row v-if="subscriptionStatus.subscription_status !== 'active'">
<!--            <b-col cols="12">-->
<!--                <h3>{{ i18nT(`Subscription plans`) }}</h3>-->
<!--                <p>{{ i18nT(`Choose a subscription plan that fits your needs.`) }}</p>-->
<!--            </b-col>-->
            <b-col sm="12" class="text-center">
                <b-form-group
                    :label="i18nT(`Pick your plan and billing period`)"
                >
                    <b-form-checkbox
                        v-model="yearlyMode"
                        class="mr-0 mt-50"
                        switch
                        inline
                    />
                    <span class="d-inline">{{billingModeText}}</span>
                </b-form-group>
            </b-col>

            <b-col cols="12">
                <b-table
                    class="rounded-top text-center overflow-hidden"
                    :items="featureList"
                    :fields="featureListFields"
                >
                    <!-- HEADERS -->
                    <template #head(Feature)="">
                        <span class="skp-text-left text-left w-100">

                        </span>
                    </template>
                    <template #head(Starter)="data">
                        <h4 class="">{{data.label}}</h4>
                        <h5 class="text-primary sk-pricing">{{data.field.pricing[billingMode].price | money}} <sup>€</sup> / {{billingModePeriod}}</h5>
                    </template>
                    <template #head(Grow)="data">
                        <h4 class="">{{data.label}}</h4>
                        <h5 class="text-primary sk-pricing">{{data.field.pricing[billingMode].price | money}} <sup>€</sup> / {{billingModePeriod}}</h5>
                    </template>
                    <template #head(Flourish)="data">
                        <h4 class="">{{data.label}}</h4>
                        <h5 class="text-primary sk-pricing">{{data.field.pricing[billingMode].price | money}} <sup>€</sup> / {{billingModePeriod}}</h5>
                    </template>


                    <template #cell(Feature)="data">
                        <span class="skp-text-left text-left w-100">
                            {{data.item.Feature}}
                        </span>
                    </template>

                    <template #cell(Starter)="data" >

                        <span v-if="data.item.Starter.text">{{data.item.Starter.text}}</span>

                        <b-button
                            v-else-if="data.item.Starter.btnText"
                            variant="outline-primary"
                            class="ml-1 mt-2"
                            size="lg"
                            @click="signup('Grow')">
                            {{data.item.Starter.btnText}}
                        </b-button>

                        <font-awesome-icon
                            v-else-if="typeof(data.item.Starter.btn) === 'undefined'"
                            :icon="['fas','check']"
                            :class="data.item.Starter ? 'text-primary' : 'text-muted'"
                        />

                    </template>

                    <template #cell(Grow)="data">

                        <span v-if="data.item.Grow.text">{{data.item.Grow.text}}</span>
                        <font-awesome-icon
                            v-else-if="!data.item.Grow.btn"
                            :icon="['fas','check']"
                            :class="data.item.Grow ? 'text-primary' : 'text-muted'"
                        />
                        <b-button
                            v-if="data.item.Grow.btn"
                            variant="outline-primary"
                            class="ml-1 mt-1 sk-less-padding"
                            size="md"
                            @click="signup('Grow')">
                            {{data.item.Grow.btnText}}
                        </b-button>
                    </template>

                    <template #cell(Flourish)="data">

                        <span v-if="data.item.Flourish.text">{{data.item.Flourish.text}}</span>
                        <font-awesome-icon
                            v-else-if="!data.item.Flourish.btn"
                            :icon="['fas','check']"
                            :class="data.item.Flourish ? 'text-primary' : 'text-muted'"
                        />
                        <b-button
                            v-if="data.item.Flourish.btn"
                            variant="primary"
                            class="ml-1 mt-1 sk-less-padding"
                            size="md"
                            @click="signup('Flourish')">
                            {{data.item.Flourish.btnText}}
                        </b-button>
                    </template>

                </b-table>
            </b-col>
        </b-row>

        <!-- MODAL CHANGE PAYMENT METHOD -->
        <b-modal
            :title="i18nT(`Change payment method`)"
            :ok-title="i18nT(`Save`)"
            @ok="updatePaymentMethod"
            no-close-on-backdrop
            id="modal-change-method">
            <b-row>
                <b-col cols="12" class="text-center my-2">
                    <font-awesome-icon :icon="['fas','credit-card']"
                                       class="text-primary mb-2" size="3x" />
                    <h4>{{i18nT(`Change or update your credit card details`)}}</h4>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" class="pb-1">
                    <p>{{i18nT(`To update your card with a new one, you may simply enter the new cardholder and card details below.`)}}</p>
                </b-col>
                <b-col cols="6">
                    <b-form-group :label="i18nT(`Cardholder names`)" label-for="signupFirstName">
                        <b-form-input
                            id="cardNames"
                            type="text"
                            v-model="cardNames"></b-form-input>
                    </b-form-group>
                </b-col>

                <b-col cols="6">
                    <b-form-group :label="i18nT(`Cardholder E-mail`)" label-for="cardEmail">
                        <b-form-input
                            id="cardEmail"
                            type="email"
                            v-model="cardEmail"></b-form-input>
                    </b-form-group>
                </b-col>

                <b-col cols="12">
                    <div id="card-mount"
                         ref="card-mount"
                         class="mt-2 border rounded p-2"
                    ></div>
                </b-col>
            </b-row>

        </b-modal>

        <!-- MODAL CHANGE PLAN -->
        <b-modal
            :title="i18nT(`Change plan`)"
            :ok-title="i18nT(`Save`)"
            @ok="updatePlan"
            no-close-on-backdrop
            id="modal-change-plan"
        >
            <b-row class="border-bottom mb-2">
                <b-col md="12 py-2 text-center">
                    <div class="brand-logo mb-2">
                        <vuexy-logo />
                    </div>
                    <p class="text-muted">{{i18nT(`Your current plan is`)}}</p>
                    <h3 class="text-uppercase">{{currentPlan.Label}}</h3>
                    <p>{{i18nT(`This includes all premium features of Skileo with`)}}  <strong>{{featureList[0][signupVariant].text}}</strong></p>
                    <div class="price-block">
                        <h2 class="text-primary sk-pricing">{{currentPlan.Price.amount | money}} <sup>€</sup> / {{i18nT(billingModePeriod)}}</h2>
                    </div>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12">
                    <div class="text-center">
                        <b-form-group :label="i18nT(`Billing period`)">
                            <b-form-radio-group
                                id="period-radio-group"
                                v-model="switchPlanPeriod"
                                button-variant="outline-primary"
                                buttons
                                name="selectedPeriod"
                                :options="periodOptions"
                            />
                        </b-form-group>
                    </div>

                </b-col>
            </b-row>

            <b-row class="mb-2">
                <b-col cols="12">
                    <b-list-group-item tag="li" class="sk-list-group-item py-1 rounded-top">
                        <div class="">
                            <b-row>
                                <b-col cols="8">
                                    <b-form-radio
                                        v-model="newPlan.Label"
                                        name="selectedPlan"
                                        value="Starter"
                                        unchecked-value=""
                                        class="sk-radio">
                                        {{i18nT(`Starter`)}}
                                    </b-form-radio>
                                </b-col>
                                <b-col cols="4" class="text-right">
                                    <h5 class="text-primary sk-pricing">{{0.00 | money}} <sup>€</sup> / {{i18nT(switchPlanPeriod)}}</h5>
                                </b-col>
                            </b-row>
                        </div>
                    </b-list-group-item>
                    <b-list-group-item tag="li" class="sk-list-group-item py-1">
                        <div class="">
                            <b-row>
                                <b-col cols="8">
                                    <b-form-radio
                                        v-model="newPlan.Label"
                                        name="selectedPlan"
                                        value="Grow"
                                        unchecked-value=""
                                        class="sk-radio">
                                        {{i18nT(`Grow`)}}
                                    </b-form-radio>
                                </b-col>
                                <b-col cols="4" class="text-right">
                                    <h5 class="text-primary sk-pricing">{{29.99 | money}} <sup>€</sup> / {{i18nT(switchPlanPeriod)}}</h5>
                                </b-col>
                            </b-row>
                        </div>
                    </b-list-group-item>
                    <b-list-group-item tag="li" class="sk-list-group-item py-1 rounded-bottom">
                        <div class="">
                            <b-row>
                                <b-col cols="8">
                                    <b-form-radio
                                        v-model="newPlan.Label"
                                        name="selectedPlan"
                                        value="Flourish"
                                        unchecked-value=""
                                        class="sk-radio">
                                        {{i18nT(`Flourish`)}}
                                    </b-form-radio>
                                </b-col>
                                <b-col cols="4" class="text-right">
                                    <h5 class="text-primary sk-pricing">{{99.90 | money}} <sup>€</sup> / {{i18nT(switchPlanPeriod)}}</h5>
                                </b-col>
                            </b-row>
                        </div>
                    </b-list-group-item>
                </b-col>
            </b-row>


            <b-row id="plan-disclaimer" v-if="newPlan.Label == 'Starter'">
                <b-col cols="12">
                    <span class="text-danger">
                        <font-awesome-icon :icon="['fas', 'exclamation-triangle']" />
                        {{i18nT(`If you downgrade to the "Starter" plan you will lose access to some Skileo features such as`)}} <strong>{{i18nT(`Bank account sync`)}}, {{i18nT(`Multi office`)}} and {{i18nT(`Multi users`)}}!</strong>
                    </span>
                </b-col>
            </b-row>

        </b-modal>

        <!-- Modal Signup -->
        <b-modal
            :title="i18nT(`Sign-up`)"
            :ok-title="i18nT(`Next`)"
            no-close-on-backdrop

            @ok="payInvoice"
            id="modal-signup"
        >
            <template #modal-footer>
                <b-button
                    variant="secondary"
                    size="md"
                    class=""
                    @click="$bvModal.hide('modal-signup')"
                >
                    {{i18nT(`Cancel`)}}
                </b-button>

                <b-button
                    variant="outline-primary"
                    size="md"
                    class="ml-1"
                    :disabled="signupStep == 1"
                    @click="stepBack"
                >
                    {{i18nT(`Back`)}}
                </b-button>

                <b-button
                    variant="primary"
                    size="md"
                    class="ml-1"
                    @click="payInvoice"
                >
                    {{i18nT(`Next`)}}
                </b-button>

            </template>

            <transition
                name="fade"
                tag="div"
            >
                <div class="sk-spinner-overlay" v-if="loading">
                    <div class="sk-spinner-and-text w-50 text-center">
                        <b-spinner
                            :variant="`primary`"
                            class="spinner-overlay__spinner"
                        />
                        <span class="mt-2 d-block">{{i18nT(`Please wait...`)}}</span>
                    </div>
                </div>
            </transition>

            <b-row class="border-bottom mb-3">
                <b-col md="12 py-2 text-center">
                    <div class="brand-logo mb-2">
                        <vuexy-logo />
                    </div>
                    <h3 class="text-uppercase">{{signupVariant}}</h3>
                    <p>{{i18nT(`This includes all premium features of Skileo with`)}}  <strong>{{featureList[0][signupVariant].text}}</strong></p>
                    <div class="price-block">
                        <h2 class="text-primary sk-pricing">{{selectedPlan.PackageFee | money}} <sup>€</sup> / {{billingModePeriod}}</h2>
                    </div>
                </b-col>
            </b-row>


            <transition-group
                type="transition"
                name="fade"
                @after-enter="onStepChanged"
            >
                <div
                    class="sk-payment-step"
                    v-if="signupStep === 1"
                    key="step1">
                    <validation-observer ref="clientData">
                        <b-row>
                        <b-col md="12">
                            <h4>{{i18nT(`Billing information`)}}</h4>
                        </b-col>

                        <b-col md="12">
                            <div class="d-flex justify-content-between">
                                <h5 class="pt-1">{{signupCompName}}</h5>
                                <div class="text-right">
                                <span class="text-right cursor-pointer" @click="$router.push({name: 'company-profile'})">
                                    <feather-icon
                                        icon="EditIcon"
                                        class="text-primary"
                                    ></feather-icon>
                                </span>
                                </div>
                            </div>

                        </b-col>

                        <b-col md="6">
                            <p v-if="signupVatID" class="mb-0">{{signupVatID}}</p>
                            <p v-else-if="signupUIC" class="mb-0">{{signupUIC}}</p>
                            <p v-html="signupAddress" class="mb-0"></p>
                        </b-col>

                        <b-col md="6">
                            <p v-if="signupNames" class="mb-0">{{signupNames}}</p>
                            <p v-if="signupEmail" class="mb-0">{{signupEmail}}</p>
                        </b-col>

                            <div v-if="false">
                                <b-col md="6">
                                    <validation-provider
                                        #default="{ errors }"
                                        :name="i18nT(`Representative names`)"
                                        rules="required"
                                    >
                                        <b-form-group :label="i18nT(`Representative names`)"
                                                      label-for="signupNames"
                                                      class="required"
                                        >
                                            <b-form-input
                                                id="signupNames"
                                                type="text"
                                                required
                                                v-model="signupNames"></b-form-input>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </b-form-group>

                                    </validation-provider>
                                </b-col>
                                <b-col md="6">
                                    <validation-provider
                                        #default="{ errors }"
                                        :name="i18nT(`E-mail`)"
                                        rules="required|email"
                                    >
                                        <b-form-group
                                            :label="i18nT(`E-mail`)" label-for="signupEmail"
                                            class="required"
                                        >
                                            <b-form-input
                                                id="signupEmail"
                                                type="email"
                                                email
                                                v-model="signupEmail"></b-form-input>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </b-form-group>
                                    </validation-provider>
                                </b-col>


                                <b-col md="6">
                                    <validation-provider
                                        #default="{ errors }"
                                        :name="i18nT(`Company name`)"
                                        rules="required"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Company name`)"
                                            label-for="signupCompName"
                                            class="required"
                                        >
                                            <b-form-input
                                                id="signupCompName"
                                                type="text"
                                                required
                                                v-model="signupCompName"></b-form-input>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </b-form-group>
                                    </validation-provider>
                                </b-col>
                                <b-col md="6">
                                    <b-form-group :label="i18nT(
                                `UIC`)" label-for="signupUIC">
                                        <b-form-input
                                            id="signupUIC"
                                            v-model="signupUIC"></b-form-input>
                                    </b-form-group>
                                </b-col>

                                <b-col md="6">
                                    <b-form-group
                                        :label="i18nT(`Company is VAT registered`)"
                                    >
                                        <b-form-checkbox
                                            v-model="signupHasVat"
                                            class="mr-0 mt-50"
                                            switch
                                            inline
                                        />
                                    </b-form-group>
                                </b-col>
                                <b-col md="6">
                                    <b-form-group
                                        v-if="signupHasVat"
                                        :label="i18nT(`VAT ID`)" label-for="signupVatID">
                                        <b-form-input
                                            id="signupVatID"
                                            type="text"
                                            v-model="signupVatID"></b-form-input>
                                    </b-form-group>
                                </b-col>

                                <b-col md="12">
                                    <validation-provider
                                        #default="{ errors }"
                                        :name="i18nT(`Billing address`)"
                                        rules="required"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Billing Address`)"
                                            label-for="signupAddress"
                                            class="required"
                                        >
                                            <b-form-textarea
                                                v-model="signupAddress"
                                                id="signupAddress"
                                                rows="4"
                                                required
                                            >
                                            </b-form-textarea>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </b-form-group>
                                    </validation-provider>
                                </b-col>
                            </div>

                    </b-row>
                    </validation-observer>
                </div>

                <div
                    class="sk-payment-step"
                    v-if="signupStep === 2"
                    key="step2">
                    <b-row>
                        <b-col md="12 py-2">
                            <h4 class="m-neg-2">{{i18nT(`Your payment details`)}}</h4>
                            <p class="d-inline-block">{{i18nT(`Payment is securely processed with`)}} </p><span class="d-inline-block sk-logo-span"><font-awesome-icon :icon="['fab','stripe']" size="4x" class="text-primary"></font-awesome-icon></span>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="6">
                            <b-form-group :label="i18nT(`Cardholder names`)" label-for="signupFirstName">
                                <b-form-input
                                    id="cardNames"
                                    type="text"
                                    v-model="cardNames"></b-form-input>
                            </b-form-group>
                        </b-col>

                        <b-col cols="6">
                            <b-form-group :label="i18nT(`Cardholder E-mail`)" label-for="cardEmail">
                                <b-form-input
                                    id="cardEmail"
                                    type="email"
                                    v-model="cardEmail"></b-form-input>
                            </b-form-group>
                        </b-col>

                        <b-col cols="12">
                            <div id="card-mount"
                                 ref="card-mount"
                                 class="mt-2 border rounded p-2"
                                 ></div>
                        </b-col>
                    </b-row>


                </div>


            </transition-group>

        </b-modal>

    </b-card>
</template>

<script>
import {
    BButton,
    //BImg,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BCard,

    BListGroupItem,
    // BFormSelect,
    BTable,
    BSpinner,
    VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required, email} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
    StripePlugin,
    //StripeElementCard
} from '@vue-stripe/vue-stripe';
import Vue from "vue";
import useAuth from "@/auth/useAuth";
import {dictToSelectArray} from "@core/utils/utils";
//import {dictToSelectArray} from '@core/utils/utils'
//import {useUtils as useI18Utils} from "@core/libs/i18n";
//const {i18n} = useI18Utils();

export default {
    components: {
        BButton,
        //BImg,
        BFormCheckbox,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BFormRadioGroup,
        BFormRadio,

        BRow,
        BCol,
        BCard,
        ValidationProvider,
        ValidationObserver,

        BListGroupItem,
        BTable,
        BSpinner,
        // BFormSelect
        VuexyLogo
    },
    directives: {
        Ripple,
        'b-modal': VBModal
    },
    props: {
        generalData: {
            type: Object,
            default: () => {
            }
        }
    },
    data() {
        return {
            loading: false,
            featureList: [
                {
                    Feature: this.i18nT(`Employee limit`),
                    Starter: {text: this.i18nT(`5 employees`)},
                    Grow:    {text: this.i18nT(`50 employees`)},
                    Flourish: {text: this.i18nT(`50+ employees`)},
                },
                {
                    Feature: this.i18nT(`Employee management`),
                    Starter: true,
                    Grow: true,
                    Flourish: true,
                },
                {
                    Feature: this.i18nT(`Talent management`),
                    Starter: true,
                    Grow: true,
                    Flourish: true,
                },
                {
                    Feature: this.i18nT(`Employee portal`),
                    Starter: true,
                    Grow: true,
                    Flourish: true,
                },
                {
                    Feature: this.i18nT(`Engagement and surveys`),
                    Starter: true,
                    Grow: true,
                    Flourish: true,
                },
                {
                    Feature: this.i18nT(`Document storage`),
                    Starter: true,
                    Grow: true,
                    Flourish: true,
                },
                {
                    Feature: this.i18nT(`Invoicing`),
                    Starter: true,
                    Grow: true,
                    Flourish: true,
                },
                {
                    Feature: this.i18nT(`Bank account sync`),
                    Starter: false,
                    Grow: true,
                    Flourish: true,
                },
                {
                    Feature: this.i18nT(`Multi office`),
                    Starter: false,
                    Grow: true,
                    Flourish: true,
                },
                {
                    Feature: this.i18nT(`Multi users`),
                    Starter: false,
                    Grow: true,
                    Flourish: true,
                },
                {
                    Feature: '',
                    Starter: {btn: false},
                    Grow: {btn: true, btnText: this.i18nT(`Sign-up`)},
                    Flourish: {btn: true, btnText: this.i18nT(`Sign-up`)},
                },
            ],
            featureListFields: [
                {key: 'Feature', label: this.i18nT('Feature')},
                {key: 'Starter', label: this.i18nT('Starter'),pricing: {yearly: {price: 0.00, id: 123}, monthly: {price: 0.00, id: 124}}},
                {key: 'Grow', label: this.i18nT('Grow'), pricing: {yearly: {price: 299.90, id: 123}, monthly: {price: 29.99, id: 124}}},
                {key: 'Flourish', label: this.i18nT('Flourish'), pricing: {yearly: {price: 999.90, id: 123}, monthly: {price: 99.90, id: 124}}},
            ],
            tableFields: [
                {key: 'InvoiceNumber', label: this.i18nT('Number')},
                {key: 'InvoiceDate', label: this.i18nT('Date')},
                {key: 'InvoiceAmount', label: this.i18nT('Amount')},
                {key: 'InvoiceDescription', label: this.i18nT('Description')},
                {key: 'InvoicePeriod', label: this.i18nT('Period')},

            ],
            periodOptions: [
                {value: 'month', text: this.i18nT('Monthly')},
                {value: 'year', text: this.i18nT('Yearly')},
            ],
            planOptions: [
                {value: 'Starter', text: this.i18nT('Starter')},
                {value: 'Grow', text: this.i18nT('Grow')},
                {value: 'Flourish', text: this.i18nT('Flourish')},
            ],
            tableCurrentPage: 1,
            billingInvoices: [],

            currentPlan: {
                Active: true,
                Label: 'Grow',
                Price: {
                    amount : 29.90,
                    currency: 'EUR',
                },
                Interval: 'month',
                IntervalCount: 1,
            },
            planPaymentMethod: {
                id: 1,
                name: 'Stripe',
                icon: 'stripe',
                method: {
                    type: 'card',
                    card: {
                        brand: 'MasterCard',
                        last4: '8016',
                        exp_month: `01`,
                        exp_year: 2024,
                    }
                },

                active: true,
            },
            ratingTemplates: [],
            currentType: {},
            types: [],
            providers: [],
            subscriptionStatus: {
                subscription_status: 'none'
            },
            subscriptionPacks: [],
            subscriptionHistory: [],
            offerCountries: [],

            yearlyMode: false,

            switchPlanPeriod: 1,
            newPlan: {
                id: 1,
                Label: 'Starter',
                price: 0.00,
                interval: 'month',
            },

            signupStep: 1,
            signupVariant: 'Starter',
            signupNames: '',
            signupEmail: '',
            signupCompName: '',
            signupUIC: '',
            signupAddress: '',
            signupHasVat: false,
            signupVatID: '',

            stripe_pk: '',
            stripe_customer_id: '',
            stripe_locale: 'en_EN',
            stripe_account_id: '',
            stripe_customer: {},

            cardNames: '',
            cardEmail: '',

            selectedPlan: {
                id: 1,
                name: 'Starter',
                price: 0.00,
                interval: 'month',
            },
        }
    },
    computed: {
        billingMode() {
            return this.yearlyMode ? 'yearly' : 'monthly';
        },
        billingModeText() {
            return this.yearlyMode ? this.i18nT(`Yearly`) : this.i18nT(`Monthly`);
        },
        billingModePeriod() {
            return this.yearlyMode ? this.i18nT(`Year`) : this.i18nT(`Month`);
        },

        signupAddressComposite() {
            let curWorkspace = useAuth.getCurWorkspace();
            if(curWorkspace && curWorkspace.address) {
                return curWorkspace.address;
            }

            return this.signupAddress;
        },

    },
    methods: {
        updatePaymentMethod() {

        },
        openUpdatePaymentMethod(){
            this.$bvModal.show('modal-change-method');

            if(this.stripe_pk){
                let stripeOpts = {
                    pk: this.stripe_pk,
                    testMode: true, // Boolean. To override the insecure host warning
                    //stripeAccount: this.stripe_account_id,
                    // apiVersion: '2020-08-27',
                    locale: this.locale
                };

                Vue.use(StripePlugin, stripeOpts);

                this.cardElement = this.$stripe.elements({locale: this.locale}).create('card');
                this.cardElement.mount('#change-card-mount');
            }

        },
        updatePlan(plan) {
            this.selectedPlan = plan;
        },
        showError(message){
            if(!message) { message = this.i18nT(`An error has occurred. Please try again later.`) }
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    message: message
                },
                // onClose: () => {
                //     this.loading = false;
                // }
            });
            this.loading = false;
        },
        getNameByCountryId(countryId) {
            const country = this.offerCountries.find(con => con.value == countryId)
            return country ? country.text : ''
        },
        stepBack() {
            this.signupStep--;
        },
        onStepChanged() {

            if(this.stripe_pk && this.signupStep == 2 ){
                let stripeOpts = {
                    pk: this.stripe_pk,
                    testMode: true, // Boolean. To override the insecure host warning
                    //stripeAccount: this.stripe_account_id,
                    // apiVersion: '2020-08-27',
                    locale: this.locale
                };

                Vue.use(StripePlugin, stripeOpts);
            }

            if( this.signupStep == 2 && this.stripe_pk) {

                this.cardElement = this.$stripe.elements({locale: this.locale}).create('card');
                this.cardElement.mount('#card-mount');

            }

        },

        payInvoice(bvModalEvent) {
            let formData = new FormData();
            bvModalEvent.preventDefault();
            let payload = {
                type: 'card',
                card: {
                    number: null,
                    exp_month: null,
                    exp_year: null,
                    last4: null,
                }
            }

            let self = this;
            switch (this.signupStep) {

                //STEP 1 - Enter payer name and Email -> Get payment OBJECT from server
                case 1:

                    this.$refs.clientData.validate().then(success => {
                        if (success) {
                            formData.append('payer_name', this.signupNames);
                            formData.append('payer_email', this.signupEmail);

                            this.loading = true;
                            this.$http.post('subscriptions/customer', formData).then((response) => {
                                console.log("Subscription response: ", response.data);

                                this.stripe_pk = response.data.data.stripe_pk;
                                this.stripe_customer = response.data.data.stripe_customer;
                                this.stripe_customer_id = response.data.data.stripe_customer_id;
                                this.stripe_locale = response.data.data.locale;
                                // this.stripe_account_id = response.data.data.payment.ConnectedAccount;

                                this.signupStep++;
                                this.loading = false;

                            }).catch((error) => {

                                this.$toast({
                                    component: ToastificationContent,
                                    position: 'top-right',
                                    props: {
                                        title: this.errorMessage(error),
                                        icon: 'AlertTriangleIcon',
                                        variant: 'danger',
                                        type: 'error',
                                        timeout: 5000,
                                    },
                                });

                                this.loading = false;
                            });

                        }
                    });
                    break;

                case 2:
                    if(this.cardElement){
                        payload.card = this.cardElement;
                    }
                    this.loading = true;
                    this.$stripe.createPaymentMethod(payload).then((resp) => {
                        let method = resp.paymentMethod;
                        if(method.id){
                            let formData = new FormData();

                            formData.append('subscription_package', self.selectedPlan.PackageId);
                            formData.append('stripe_payment_method', method.id);
                            formData.append('payer_name', self.cardNames);
                            formData.append('payer_email', self.cardEmail);
                            this.$http.post('subscriptions/subscribe', formData).then((response) => {
                                console.log("Subscription response: ", response.data);
                                self.$toast({
                                    component: ToastificationContent,
                                    position: 'top-right',
                                    props: {
                                        title: this.i18nT(`Your payment was successfully registered!`),
                                        icon: 'AlertTriangleIcon',
                                        variant: 'success'
                                    }
                                })
                                self.loading = false;
                                this.signupStep++;
                                this.$bvModal.hide('modal-signup');

                            }).catch((error) => {
                                this.showError(error.message);
                                console.log("confirmCardPayment Error: ", error.message);
                            });

                        }

                    }).catch((err) => {
                        this.showError(err.message);
                        console.log("Stripe create payment Error: ", err.message);
                    });

                    break;
            }
        },
        signup(variant) {
            this.$bvModal.show('modal-signup');
            this.subscriptionPacks.forEach(plan => {
                if (plan.Label === variant && plan.PeriodTypeText === this.billingMode) {
                    this.selectedPlan = plan;
                }
            });

            this.signupVariant = variant;
        },
        toggleForInvoice(provider) {
            let formData = new FormData();
            formData.append('payment_provider_id', provider.Id);
            formData.append('enable', provider.Enabled ? 1 : 0);
            this.$http.post('settings/UsePaymentProvider', formData)
                .then(response => {
                    //this.providers[index].Enabled = response.data;
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.successMessage(response),
                            icon: 'InfoIcon',
                            variant: 'success'
                        }
                    })
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.errorMessage(err),
                            icon: 'AlertTriangleIcon',
                            variant: 'danger'
                        }
                    })
                })
        },

        statusLabelClass(provider) {
            return {
                'connected': 'success',
                'pending': 'warning',
                'not_connected': 'light-gray'
            }[provider.Status]
        },
        connect(provider, index){
            window.open(provider.ConnectUrl, '_blank');
            this.providers[index].Status = 'pending';
        },
        disconnect(provider){
            let formData = new FormData();
            formData.append('payment_provider_id', provider.Id);
            this.$http.post(`settings/disconnect`, formData);
        },
        resetForm() {
            this.currentType = {}
        },
    },
    setup() {
        return {
            required,
            email
        }
    },
    created() {
        Promise.all([
            new Promise((res) => {
                this.$http.get('subscriptions/status').then(({data}) => {
                    this.subscriptionStatus = data.data;
                    res();
                });
            }),

            new Promise((res) => {
                this.$http.get(`subscriptions/history`).then(({data}) => {
                    this.subscriptionHistory = data.data;
                    console.log("Subscription history: ", data.data);
                    res();
                })
            }),
            new Promise((res) => {
                this.$http.get(`system/countries`).then(({data}) => {
                    this.offerCountries = dictToSelectArray(data.data)
                    res();
                })
            }),
            new Promise((res) => {
                this.$http.get('subscriptions/packages').then(({data}) => {
                    this.subscriptionPacks = data.data
                    console.log("Subscription packs: ", this.subscriptionPacks);

                    if(this.subscriptionPacks.length > 0) {
                        //this.currentPlan = this.subscriptionPacks.find((pack) => pack.Active)

                        this.subscriptionPacks.forEach((pack) => {
                            if(pack.PeriodType==='montly') {
                                pack.Price.amount = pack.Price.amount / 12;
                            }
                            this.featureListFields.find((field) => field.key === pack.Label).pricing[pack.PeriodTypeText].price = pack.PackageFee;
                            this.featureListFields.find((field) => field.key === pack.Label).pricing[pack.PeriodTypeText].Id = pack.PackageId;
                        });
                    }
                    res();
                })
            }),

        ]).then(() => {

            let curWorkspace = useAuth.getCurWorkspace();

            if(curWorkspace && this.subscriptionStatus.subscription_status !== 'active'){
                if(curWorkspace.BillingPerson) this.signupNames = curWorkspace.BillingPerson; this.cardNames = curWorkspace.BillingPerson
                if(curWorkspace.BillingEmail) this.signupEmail = curWorkspace.BillingEmail; this.cardEmail = curWorkspace.BillingEmail;

                if(curWorkspace.Label) this.signupCompName = curWorkspace.Label;

                if(curWorkspace.Vat) {
                    this.signupVatID = curWorkspace.Vat;
                    this.signupUIC = curWorkspace.Vat;
                    this.signupHasVat = true;
                }

                if(curWorkspace.BillingAddress) this.signupAddress += curWorkspace.BillingAddress+ ',<br/>';
                if(curWorkspace.BillingZip) this.signupAddress += curWorkspace.BillingZip;
                if(curWorkspace.BillingCity) this.signupAddress += ', '+curWorkspace.BillingCity;
                if(curWorkspace.BillingState) this.signupAddress += '<br/>'+curWorkspace.BillingState;
                if(curWorkspace.BillingCountry) this.signupAddress += '<br/>' + this.getNameByCountryId(curWorkspace.BillingCountry);

            }

            if(this.subscriptionStatus.subscription_status === 'active'){
                let thePlan = this.subscriptionPacks.find((pack) => pack.PackageId === this.subscriptionStatus.subscription.PackageId);

                this.currentPlan = {
                    Label: thePlan.Label,
                    PeriodTypeText: thePlan.PeriodTypeText,
                    Price: {
                        amount: thePlan.PackageFee,
                        currency: thePlan.Currency
                    },
                    PackageId: thePlan.PackageId,
                    Active: true,
                    ExpiresAt: this.subscriptionStatus.subscription.ExpirationDate,
                };

                thePlan.PeriodType === 1 ? this.yearlyMode = false : this.yearlyMode = true;
                this.yearlyMode ? this.currentPlan.Interval = 'month' : this.currentPlan.Interval = 'year';

                this.switchPlanPeriod = this.currentPlan.Interval;
            }

            if(this.subscriptionHistory.length > 0) {

                this.subscriptionHistory.forEach((history) => {
                   let invoiceRow = {
                       id: history.Id,
                       number:  history.InvoiceObj.InternalNumber,
                       invoiceId: history.InvoiceObj.Id,
                       invoiceUrl: history.InvoiceObj.InvoiceDownloadUrl,
                       description: history.InvoiceObj.Description,
                       date: history.CreatedAt,
                       price: {
                           amount: history.Price,
                           currency: history.Currency,
                       },
                       periodStart: history.StartDate,
                       periodEnd: history.ExpirationDate,
                   }

                   this.billingInvoices.push(invoiceRow);
                });
            }

            this.loading = false;
        });

    },
    watch: {
        signupStep: function (val) {
            console.log("Signup step is: ", val);
        }
    },
}
</script>

<style lang="scss">

.m-neg-2 {
    margin-bottom: -20px;
}

.sk-less-padding {
    padding: 12px;
}

.sk-logo-span {
    position: relative;
    bottom: -12px;
    margin-left: 5px;
}

.brand-logo {
    width: 150px;
    position: relative;
    display: inline-block;
}

.sk-payment-step {
    min-height: 230px;
}

.sk-toggles {
    height: 100%;
}

.sk-provider-icon {
    color: var(--gray);
}

.active-provider .sk-provider-icon {
    color: var(--primary);
}

.draggable-container {
    display: flex;
    justify-content: space-between;
}

td:has(.skp-text-left) {
    text-align: left;
}

</style>
