<template>

        <b-tabs
            vertical
            content-class="col-12 col-md-9 mt-1 mt-md-0"
            pills
            nav-wrapper-class="col-md-3 col-12"
            nav-class="nav-left"
            v-model="tabIndex"
            v-if="ready"
        >

            <!-- 12: GENERAL -->
            <b-tab
                v-if="tabIsAvailable('configuration')"
                @click="changeTab('configuration')">
                <template #title>
                    <feather-icon
                        icon="SettingsIcon"
                        size="18"
                        class="mr-50"
                    />
                    <span class="font-weight-bold">{{ i18nT(`Configuration`) }}</span>
                </template>
                <settings-config :general-data="options"/>
            </b-tab>

            <!-- 1: MEMBERS -->
            <b-tab
                v-if="tabIsAvailable('members') && options.Type === 'client'"
                @click="changeTab('members')">
                <template #title>
                    <feather-icon
                        icon="UsersIcon"
                        size="18"
                        class="mr-50"
                    />
                    <span class="font-weight-bold">{{ i18nT(`Members`) }}</span>
                </template>

                <settings-members
                    v-if="options"
                    :general-data="options"
                />
            </b-tab>


            <!-- 2: MESSAGES-->
            <b-tab
                v-if="tabIsAvailable('message-templates')"
                @click="changeTab('message-templates')">
                <template #title>
                    <feather-icon
                        icon="MessageSquareIcon"
                        size="18"
                        class="mr-50"
                    />
                    <span class="font-weight-bold">{{ i18nT(`Messages templates`) }}</span>
                </template>
                <settings-messages :general-data="options"/>
            </b-tab>

            <!-- 3: TASK TYPES -->
            <b-tab
                v-if="tabIsAvailable('task-types')"
                @click="changeTab('task-types')">
                <template #title>
                    <feather-icon
                        icon="TrelloIcon"
                        size="18"
                        class="mr-50"
                    />
                    <span class="font-weight-bold">{{ i18nT(`Task types`) }}</span>
                </template>
                <settings-task-types :general-data="options"/>
            </b-tab>

            <!-- 4: DOCUMENT TYPES -->
            <b-tab
                v-if="tabIsAvailable('document-types')"
                @click="changeTab('document-types')">
                <template #title>
                    <feather-icon
                        icon="FileIcon"
                        size="18"
                        class="mr-50"
                    />
                    <span class="font-weight-bold">{{ i18nT(`Document types`) }}</span>
                </template>
                <settings-doc-types :general-data="options"/>
            </b-tab>

            <!-- 5: RECRUITING PIPELINE -->
            <b-tab v-if="tabIsAvailable('recruiting-pipleline')"
                   @click="changeTab('recruiting-pipleline')">
                <template #title>
                    <feather-icon
                        icon="ListIcon"
                        size="18"
                        class="mr-50"
                    />
                    <span class="font-weight-bold">{{ i18nT(`Recruiting pipeline`) }}</span>
                </template>
                <settings-pipeline :general-data="options"/>
            </b-tab>

            <!-- 6: ONBOARDING PIPELINE -->
            <b-tab
                v-if="tabIsAvailable('onboariding-pipleline')"
                @click="changeTab('onboariding-pipleline')">
                <template #title>
                    <feather-icon
                        icon="ListIcon"
                        size="18"
                        class="mr-50"
                    />
                    <span class="font-weight-bold">{{ i18nT(`Onboarding pipeline`) }}</span>
                </template>
                <settings-pipeline-onboarding/>
            </b-tab>

            <!-- 7: OFFBOARDING PIPELINE -->
            <b-tab
                v-if="tabIsAvailable('offboarding-pipleline')"
                @click="changeTab('offboarding-pipleline')">
                <template #title>
                    <feather-icon
                        icon="ListIcon"
                        size="18"
                        class="mr-50"
                    />
                    <span class="font-weight-bold">{{ i18nT(`Offboarding pipeline`) }}</span>
                </template>
                <settings-pipeline-offboarding/>
            </b-tab>

            <!-- 8: HOLIDAYS -->
            <b-tab
                v-if="tabIsAvailable('holidays')"
                @click="changeTab('holidays')">
                <template #title>
                    <feather-icon
                        icon="CoffeeIcon"
                        size="18"
                        class="mr-50"
                    />
                    <span class="font-weight-bold">{{ i18nT(`Holidays`) }}</span>
                </template>
                <settings-holidays :general-data="options"/>
            </b-tab>

            <!-- 9: ABSENCE TYPES -->
            <b-tab v-if="tabIsAvailable('absence-types')"
                   @click="changeTab('absence-types')">
                <template #title>
                    <feather-icon
                        icon="BellOffIcon"
                        size="18"
                        class="mr-50"
                    />
                    <span class="font-weight-bold">{{ i18nT(`Time off`) }}</span>
                </template>
                <settings-absence-types :general-data="options"/>
            </b-tab>

            <!-- 10: SCORE TEMPLATES -->
            <b-tab
                v-if="tabIsAvailable('score-templates')"
                @click="changeTab('score-templates')">
                <template #title>
                    <feather-icon
                        icon="ThumbsUpIcon"
                        size="18"
                        class="mr-50"
                    />
                    <span class="font-weight-bold">{{ i18nT(`Score templates`) }}</span>
                </template>
                <settings-score-templates :general-data="options"/>
            </b-tab>


            <!-- 11: PERFORMANCE RATINGS -->
            <b-tab
                v-if="tabIsAvailable('performance-ratings')"
                @click="changeTab('performance-ratings')">
                <template #title>
                    <feather-icon
                        icon="StarIcon"
                        size="18"
                        class="mr-50"
                    />
                    <span class="font-weight-bold">{{ i18nT(`Performance Ratings`) }}</span>
                </template>
                <settings-performance-ratings :general-data="options"/>
            </b-tab>




            <!-- 13: PAYMENT PROVIDERS -->
            <b-tab
                v-if="tabIsAvailable('payment-providers')"
                @click="changeTab('payment-providers')">
                <template #title>
                    <feather-icon
                        icon="CreditCardIcon"
                        size="18"
                        class="mr-50"
                    />
                    <span class="font-weight-bold">{{ i18nT(`Payment providers`) }}</span>
                </template>
                <settings-payment-providers :general-data="options"/>
            </b-tab>

            <!-- 16: QUOTE MESSAGE TEMPLATES -->
            <b-tab
                v-if="tabIsAvailable('quote-messages')"
                @click="changeTab('quote-messages')">
                <template #title>
                    <feather-icon
                        icon="BriefcaseIcon"
                        size="18"
                        class="mr-50"
                    />
                    <span class="font-weight-bold">{{ i18nT(`Quote messages`) }}</span>
                </template>
                <settings-quote-messages :general-data="options"/>
            </b-tab>

            <!-- 17: PAYMENT CATEGORIES -->
            <b-tab
                v-if="tabIsAvailable('payment-categories')"
                @click="changeTab('payment-categories')">
                <template #title>
                    <feather-icon
                        icon="BookmarkIcon"
                        size="18"
                        class="mr-50"
                    />
                    <span class="font-weight-bold">{{ i18nT(`Payment categories`) }}</span>
                </template>
                <settings-payment-categories :general-data="options"/>
            </b-tab>

            <!-- 15: INVOICE MESSAGE TEMPLATES -->
            <b-tab
                v-if="tabIsAvailable('invoice-messages')"
                @click="changeTab('invoice-messages')">
                <template #title>
                    <feather-icon
                        icon="MailIcon"
                        size="18"
                        class="mr-50"
                    />
                    <span class="font-weight-bold">{{ i18nT(`Invoice messages`) }}</span>
                </template>
                <settings-invoice-messages :general-data="options"/>
            </b-tab>

            <!-- 18: INVOICE ITEMS -->
            <b-tab
                v-if="tabIsAvailable('invoice-items')"
                @click="changeTab('invoice-items')">
                <template #title>
                    <feather-icon
                        icon="ListIcon"
                        size="18"
                        class="mr-50"
                    />
                    <span class="font-weight-bold">{{ i18nT(`Invoice items`) }}</span>
                </template>
                <settings-payment-items :general-data="options"/>
            </b-tab>

            <!-- 19: INVOICE ITEMS -->
            <b-tab
                v-if="tabIsAvailable('invoice-settings')"
                @click="changeTab('invoice-settings')">
                <template #title>
                    <feather-icon
                        icon="FilePlusIcon"
                        size="18"
                        class="mr-50"
                    />
                    <span class="font-weight-bold">{{ i18nT(`Invoice design`) }}</span>
                </template>
                <settings-invoice-design :general-data="options"/>
            </b-tab>

            <!-- 20: INVOICE SERIES -->
            <b-tab
                v-if="tabIsAvailable('invoice-series')"
                @click="changeTab('invoice-series')">
                <template #title>
                    <feather-icon
                        icon="HashIcon"
                        size="18"
                        class="mr-50"
                    />
                    <span class="font-weight-bold">{{ i18nT(`Invoice series`) }}</span>
                </template>
                <settings-invoice-series :general-data="options"/>
            </b-tab>

            <!-- 22: JOB LEVELS  -->

            <b-tab
                v-if="tabIsAvailable('job-levels')"
                @click="changeTab('job-levels')">
                <template #title>
                    <feather-icon
                        icon="UserCheckIcon"
                        size="18"
                        class="mr-50"
                    />
                    <span class="font-weight-bold">{{ i18nT(`Job levels`) }}</span>
                </template>
                <settings-job-levels :general-data="options"/>
            </b-tab>

            <!-- 14: Billing -->
            <b-tab
                v-if="tabIsAvailable('billing')"
                @click="changeTab('billing')">
                <template #title>
                    <feather-icon
                        icon="DollarSignIcon"
                        size="18"
                        class="mr-50"
                    />
                    <span class="font-weight-bold">{{ i18nT(`Subscription and billing`) }}</span>
                </template>
                <settings-billing :general-data="options"/>
            </b-tab>

        </b-tabs>

</template>

<script>
import {
    BTabs,
    BTab,
    //BNav,
    //BNavItem
} from 'bootstrap-vue'
import SettingsMembers from './SettingsMembers.vue'
// import SettingsOrganizations from './SettingsOrganizations.vue'
import SettingsMessages from './SettingsMessages.vue'
import SettingsTaskTypes from './SettingsTaskTypes.vue'
import SettingsDocTypes from './SettingsDocTypes.vue'
import SettingsPipeline from './SettingsPipeline.vue'
import SettingsScoreTemplates from './SettingsScoreTemplates.vue'
import SettingsPaymentItems from "@/views/common/Settings/SettingsInvoiceItems.vue";
import SettingsJobLevels from './SettingsJobLevels.vue'
//import SettingsFolders from './SettingsFolders.vue'
//import SettingsLabels from './SettingsLabels.vue'
import SettingsConfig from './SettingsConfig.vue'
import SettingsPerformanceRatings from './SettingsPerformanceRatings.vue'
// import SettingsOnboarding from './SettingsOnboarding.vue';
import SettingsPipelineOffboarding from './SettingsPipelineOffboarding.vue'
import SettingsPipelineOnboarding from './SettingsPipelineOnboarding.vue'
import SettingsAbsenceTypes from './SettingsAbsenceTypes.vue'
import SettingsHolidays from './SettingsHolidays.vue'
import SettingsPaymentProviders from './SettingsPaymentProviders.vue'
import SettingsBilling from './SettingsBilling.vue'
import SettingsInvoiceMessages from './SettingsInvoiceMessages.vue'
import SettingsQuoteMessages from './SettingsQuoteMessages.vue'
import SettingsPaymentCategories from "@/views/common/Settings/SettingsPaymentCategories.vue";
import SettingsInvoiceDesign from "@/views/common/Settings/SettingsInvoiceDesign.vue";
import SettingsInvoiceSeries from "@/views/common/Settings/SettingsInvoiceSeries.vue";

// import ProfileSocial from './ProfileSocial.vue'
// import ProfileNotification from './ProfileNotification.vue'
// import router from '@/router'
import useAuth from '@/auth/useAuth'
import {useUtils as useI18Utils} from "@core/libs/i18n";
const {i18n} = useI18Utils();

export default {
    components: {
        BTabs,
        BTab,
        //BNav,
        //BNavItem,
        SettingsMembers,
        //SettingsOrganizations,
        SettingsMessages,
        SettingsTaskTypes,
        SettingsDocTypes,
        SettingsPipeline,
        SettingsScoreTemplates,
        //SettingsFolders,
        //SettingsLabels,
        SettingsConfig,
        SettingsPaymentProviders,
        // ProfileSocial,
        // ProfileNotification,
        // SettingsOnboarding,
        SettingsPerformanceRatings,
        SettingsPipelineOffboarding,
        SettingsPipelineOnboarding,
        SettingsAbsenceTypes,
        SettingsHolidays,
        SettingsBilling,
        SettingsInvoiceMessages,
        SettingsQuoteMessages,
        SettingsPaymentCategories,
        SettingsPaymentItems,
        SettingsInvoiceDesign,
        SettingsInvoiceSeries,
        SettingsJobLevels
    },
    data() {
        return {
            options: {},
            filteredTabs: [],
            tabNames: {
                'configuration': 'Configuration',
                'members': 'Members',
                'message-templates': 'Message templates',
                'task-types': 'Task types',
                'document-types': 'Document types',
                'recruiting-pipleline': 'Recruiting pipleline',
                'onboariding-pipleline': 'Onboariding pipleline',
                'offboarding-pipleline': 'Offboarding pipleline',
                'holidays': 'Holidays',
                'absence-types': 'Absence types',
                'score-templates': 'Score templates',
                'performance-ratings': 'Performance ratings',
                'payment-providers': 'Payment providers',

                'quote-messages': 'Quote messages',
                'payment-categories': 'Payment categories',
                'invoice-messages': 'Invoice messages',
                'invoice-items': 'Invoice items',
                'invoice-settings': 'Invoice settings',
                'invoice-series': 'Invoice series',
                'job-levels': 'Job levels'
                //'billing': 'Payment providers',
            },
            tabSets: [
                {tn: 'configuration',           softwares: ['re', 'on', 'hr', 'en', 'ac']}, //ALL
                {tn: 'members',                 softwares: ['re', 'on', 'hr', 'en', 'ac']}, //ALL
                {tn: 'message-templates',       softwares: ['re', 'on']},
                {tn: 'task-types',              softwares: ['re', 'on', 'hr', 'en', 'ac']}, //ALL
                {tn: 'document-types',          softwares: ['re', 'on', 'hr', 'en', 'ac']}, //ALL
                {tn: 'recruiting-pipleline',    softwares: ['re']}, //RE
                {tn: 'onboariding-pipleline',   softwares: ['on']}, //ON
                {tn: 'offboarding-pipleline',   softwares: ['on']}, //ALL
                {tn: 'holidays',                softwares: ['hr']}, //HR
                {tn: 'absence-types',           softwares: ['hr']}, //HR
                {tn: 'score-templates',         softwares: ['on']}, //EN
                {tn: 'performance-ratings',     softwares: ['on']}, //ALL
                {tn: 'payment-providers',       softwares: ['ac']}, //AC
                {tn: 'quote-messages',          softwares: ['ac']}, //AC
                {tn: 'payment-categories',      softwares: ['ac']}, //AC
                {tn: 'invoice-messages',        softwares: ['ac']}, //AC
                {tn: 'invoice-items',           softwares: ['ac']}, //AC
                {tn: 'invoice-settings',          softwares: ['ac']}, //AC
                {tn: 'invoice-series',          softwares: ['ac']}, //AC
                {tn: 'job-levels',          softwares: ['hr']}, //HR
                //{tn: 'billing',                 softwares: ['re', 'on', 'hr', 'en', 'ac']}, //ALL
                ],
            tabs: [],
            tabIndex: 0,
            ready: false,
            software: null,
            offboarding: [],
            onboarding: []
        }
    },
    methods: {
        tabIsAvailable(tab) {
            return this.tabNames.includes(tab);
        },
        changeTab(tab) {
            this.tabIndex = this.tabs.findIndex(t => t === tab);
            // this.tabIndex = this.tabNames.findIndex(t => t === tab)+1;
            // this.$router.push({name: 'settings', params: {tab: tab}});
            let currentPath = this.$route.path
            if(this.$route.params.tab){
              currentPath=`/${this.software}/settings`
             }
          history.pushState({}, null, currentPath + '/' + encodeURIComponent(tab));
        },
    },
    beforeMount() {

        this.filteredTabs = this.tabSets.filter(tabSet => tabSet.softwares.includes(this.$route.params.software));
        this.tabNames = this.filteredTabs.map(tabName => tabName.tn);
        this.tabSets.forEach(tabSet => {
            if (tabSet.softwares.includes(this.$route.params.software)) {
                this.tabs.push(tabSet.tn);
            }
        });
        this.tabIndex = this.tabs.findIndex(tab => tab === this.$route.params.tab);
        //if(this.tabIndex === -1) this.tabIndex = 0;
    },
    created() {
        this.software = useAuth.getCurrentSoftware()
        if (this.software === 'ca') this.$router.push({name: 'error-404'})
    },
    beforeCreate() {
        Promise.all([
            new Promise((res) => {
                this.$http.get('users/profile').then(({data}) => {
                    this.options = data.data
                }).finally(() => {
                    res()
                })
            }),

            new Promise((res) => {
                this.$http.get('settings/pipelineStages?softwre_id=2').then(({data}) => {
                    this.onboarding = data.data
                }).finally(() => {
                    res()
                })
            }),

            new Promise((res) => {
                this.$http
                    .get('settings/pipelineStages?softwre_id=2&offboarding=1')
                    .then(({data}) => {
                        this.offboarding = data.data
                    }).finally(() => {
                        res()
                    })
            }),
        ]).then(() => {
            this.ready = true
        })

    },


    watch: {
        $router(to) {
            let curTabName = this.tabNames[to.params.tab] || 'Settings';

            to.meta.breadcrumb[1] = {
                text: i18n(curTabName),
                active: true
            }
        }

    }
}
</script>
