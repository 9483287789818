<template>
    <b-card>
        <b-row>
            <b-col sm="12">
                <h4>{{i18nT(`Payment categories`)}}</h4>
                <p>{{ i18nT(`Configure the payment categories for different types of financial documents.`) }}</p>
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mb-1 mr-1 pl-1"
                    v-b-modal.modal-payment-type
                    @click="resetForm"
                >
                    <feather-icon
                        icon="PlusCircleIcon"
                        class="mr-1"
                    />
                    {{i18nT(`Add new`)}}
                </b-button>
            </b-col>
        </b-row>
        <draggable
            v-model="documentTypes"
            tag="ul"
            class="list-group cursor-move"
            @end="updateOrder"
        >
            <b-list-group-item
                v-for="(documentType, index) in documentTypes"
                :key="index"
                tag="li"
            >
                <div class="draggable-container">
                    <div class="d-flex">
                        <div class="main-info">
                            <strong> {{ documentType.Label }}</strong>
                            <div class="font-small-2">
                                <span>{{documentType.Type == 1 ? i18nT(`Income`) : i18nT(`Expenses`)}}</span>
                            </div>
                        </div>

                    </div>

                    <div class="d-flex align-items-center">

                        <div class="variations mr-5">
                            <p class="font-small-2 mb-0">{{i18nT(`VAT Applicable`)}}:{{documentType.Vat ? i18nT(`Yes`) : i18nT(`No`)}}</p>
                            <p class="font-small-2 mb-0">{{i18nT(`Non-taxable`)}}:  {{documentType.NonTaxable ? i18nT(`Yes`) : i18nT(`No`)}}</p>
                        </div>


                        <feather-icon
                            icon="Edit2Icon"
                            size="17"
                            class="cursor-pointer d-sm-block d-none mr-1"
                            @click="onEdit(documentType)"
                        />
                        <feather-icon
                            icon="Trash2Icon"
                            size="17"
                            class="cursor-pointer d-sm-block d-none mr-1"
                            :class="{'text-muted': documentType.IsSystem}"
                            @click="onDelete(documentType)"
                        />
                    </div>
                </div>
            </b-list-group-item>
        </draggable>

        <!-- MODAL -->
        <b-modal
            id="modal-payment-type"
            :title="i18nT(`Payment category`)"
            ok-only
            :ok-title="i18nT(`Save`)"
            no-close-on-backdrop
            @ok="validationForm"
        >
            <span>{{ i18nT(`You can create and modify payment categories which you can assign to your financial documents here.`) }}</span>
            <validation-observer ref="simpleRules">
                <b-form class="mt-2">
                    <b-row>

                        <b-col sm="12">
                            <b-form-group
                                :label="i18nT(`Category name`)"
                                label-for="typeName"
                                class="required"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="typeName"
                                    rules="required"
                                >
                                    <b-form-input
                                        v-model="categoryLabel"
                                        :placeholder="i18nT(`E.g. tax return`)"
                                        name="subject"
                                        :state="errors.length > 0 ? false:null"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col sm="12">
                            <b-form-group
                                :label="i18nT(`Transaction type`)"
                                label-for="invoiceType"
                            >
                                <b-form-select
                                    v-model="categoryType"
                                    :options="typeOptions"
                                />
                            </b-form-group>
                        </b-col>

                        <b-col sm="6">
                            <b-form-group
                                label-for="taxExempt"
                                class="pt-2"
                            >
                                <b-form-checkbox
                                    name="taxExempt"
                                    switch
                                    v-model="categoryNonTaxable"
                                >
                                    {{ i18nT(`Can be tax exempt`) }}
                                </b-form-checkbox>
                            </b-form-group>
                        </b-col>
                        <b-col sm="6">
                            <b-form-group
                                label-for="vatApplies"
                                class="pt-2"
                            >
                                <b-form-checkbox
                                    name="taxExempt"
                                    switch
                                    v-model="categoryHasVAT"
                                >
                                    {{ i18nT(`VAT is applicable`) }}
                                </b-form-checkbox>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </b-modal>
    </b-card>
</template>

<script>
import {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BListGroupItem, BFormCheckbox, BFormSelect
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import draggable from 'vuedraggable'
import _ from 'lodash'
//import axiosIns from "@/libs/axios";
import {dictToSelectArray} from "@core/utils/utils";

export default {
    components: {
        BFormSelect,
        BFormCheckbox,
        BButton,
        BForm,
        BFormGroup,
        BFormInput,
        BRow,
        BCol,
        BCard,
        ValidationProvider,
        ValidationObserver,
        BListGroupItem,
        draggable
    },
    directives: {
        Ripple
    },
    props: {
        generalData: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            categoryLabel: '',
            categoryType: 1,
            categoryNonTaxable: false,
            categoryHasVAT: false,
            typeOptions: [
                { text: this.i18nT(`Income`), value: 1 },
                { text: this.i18nT(`Expenses`), value: 2 }
            ],
            recordTypeOptions: [],
            documentTypes: [],
            currentType: {},
            typeName: ''
        }
    },
    methods: {
        resetForm() {
            this.currentType = {}
            this.categoryLabel = ''
            this.categoryType = 1
            this.categoryNonTaxable = false
            this.categoryHasVAT = false

        },
        validationForm(event) {
            event.preventDefault()

            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    const formData = new FormData()
                    if (!_.isEmpty(this.currentType)) {
                        formData.append('id', this.currentType.Id)
                    }
                    formData.append('Label', this.categoryLabel)
                    formData.append('Type', this.categoryType)
                    formData.append('NonTaxable', this.categoryNonTaxable)
                    formData.append('Vat', this.categoryHasVAT)

                    this.$http
                        .post('settings/invoiceCategories', formData)
                        .then(res => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(res),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })

                            this.$bvModal.hide('modal-payment-type')
                            this.loadData()
                        })
                        .catch(err => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(err),
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                }
            })
        },
        onEdit(type) {
            this.currentType = type
            this.categoryLabel = type.Label
            this.categoryType = type.Type
            this.categoryNonTaxable = !!type.NonTaxable
            this.categoryHasVAT = !!type.Vat
            this.$bvModal.show('modal-payment-type')
        },
        onDelete(type) {

            if(type.IsSystem){
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: this.i18nT(`This is the system default category for invoices and can not be deleted.`),
                        icon: 'AlertTriangleIcon',
                        variant: 'danger'
                    }
                })

            } else {

                this.$swal({
                    title: this.i18nT(`Are you sure?`),
                    text: this.i18nT(`You won't be able to revert this!`),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: this.i18nT(`Yes, delete it!`),
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1'
                    },
                    buttonsStyling: false
                }).then(({ value }) => {
                    if (value) {
                        this.$http
                            .delete(`settings/invoiceCategories?id=${type.Id}`)
                            .then(res => {
                                this.loadData()
                                this.$toast({
                                    component: ToastificationContent,
                                    position: 'top-right',
                                    props: {
                                        title: this.successMessage(res),
                                        icon: 'InfoIcon',
                                        variant: 'success'
                                    }
                                })
                            })
                    }
                })

            }


        },
        loadData() {
            this.$http.get(`settings/invoiceCategories`).then(({ data }) => {
                this.documentTypes = data.data.sort((type1, type2) => {
                    return type1.Pos - type2.Pos
                })
            })

            this.$http.get(
                `system/recordTypes`
            ).then(({ data }) => {
                this.recordTypeOptions = dictToSelectArray(data.data)
            })
        },
        updateOrder() {
            const formData = new FormData()

            formData.append(
                'list',
                this.documentTypes
                    .filter(documentType => documentType.Id)
                    .map(documentType => documentType.Id)
                    .join(',')
            )

            this.$http
                .post('settings/savePositions', formData)
                .then(res => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.successMessage(res),
                            icon: 'InfoIcon',
                            variant: 'success'
                        }
                    })

                    this.$bvModal.hide('modal-pipeline')
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.errorMessage(err),
                            icon: 'AlertTriangleIcon',
                            variant: 'danger'
                        }
                    })
                })
        }
    },
    setup() {
        return {
            required
        }
    },
    created() {
        this.loadData()
    },
}
</script>

<style lang="scss">
.draggable-container {
    display: flex;
    justify-content: space-between;
}
</style>
