<template>
    <b-card>
        <b-row>
            <b-col sm="12">
                <h1>{{ i18nT(`Message templates`) }}</h1>
                <p>{{ i18nT(`You can create different automatic message templates.`) }}</p>
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mb-1 mr-1 pl-1"
                    v-b-modal.modal-messages
                    @click="resetForm"
                >
                    <feather-icon
                        icon="PlusCircleIcon"
                        class="mr-1"
                    />
                    {{ i18nT(`Add new`) }}
                </b-button>
            </b-col>
        </b-row>
        <!-- MODAL -->
        <b-modal
            id="modal-messages"
            :title="i18nT(`Message template`)"
            ok-only
            :ok-title="i18nT(`Save`)"
            no-close-on-backdrop
            @ok="validationForm"
        >
            <span>{{ i18nT(`Create and customize automatic message templates.`) }}</span>
            <validation-observer ref="simpleRules">
                <b-form class="mt-2">
                    <b-row>
                        <b-col sm="12">
                            <b-form-group
                                :label="i18nT(`Template name`)"
                                label-for="templateName"
                                class="required"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="Template name"
                                    rules="required"
                                >
                                    <b-form-input
                                        v-model="itemForModal.Label"
                                        :placeholder="i18nT(`E.g. Interview`)"
                                        name="templateName"
                                        :state="errors.length > 0 ? false:null"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <b-col sm="12">
                            <b-form-group
                                :label="i18nT(`Message type`)"
                                label-for="templateType"
                                class="required"
                            >
                                <validation-provider
                                    v-slot="{ errors }"
                                    name="templateType"
                                    rules="required"
                                >
                                    <b-form-select
                                        v-model="itemForModal.Type"
                                        :options="pipelines"
                                        :state="errors.length > 0 ? false:null"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <b-col sm="12">
                            <b-form-group
                                :label="i18nT(`Subject`)"
                                label-for="subject"
                                class="required"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="Subject"
                                    rules="required"
                                >
                                    <b-form-input
                                        v-model="itemForModal.Subject"
                                        :placeholder="i18nT(`E.g. Interview template`)"
                                        name="subject"
                                        :state="errors.length > 0 ? false:null"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="12">
                            <b-form-group
                                :label="i18nT(`Message`)"
                                label-for="message"
                                class="required"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="Message"
                                    rules="required"
                                >
                                    <quill-editor
                                        v-model="itemForModal.Message"
                                        :options="editorOption"
                                        id="textarea-rows"
                                        :placeholder="i18nT(`Welcome to the team...`)"
                                        rows="6"
                                        :state="errors.length > 0 ? false:null"
                                    >
                                        <div
                                            id="toolbar"
                                            slot="toolbar"
                                        >
                                            <!-- Add a bold button -->
                                            <button class="ql-bold">
                                                Bold
                                            </button>
                                            <button class="ql-italic">
                                                Italic
                                            </button>

                                            <!-- Add font size dropdown -->
                                            <select class="ql-size">
                                                <option value="small"/>
                                                <!-- Note a missing, thus falsy value, is used to reset to default -->
                                                <option selected/>
                                                <option value="large"/>
                                                <option value="huge"/>
                                            </select>

                                            <select class="ql-font">
                                                <option selected="selected"/>
                                                <option value="serif"/>
                                                <option value="monospace"/>
                                            </select>

                                            <!-- Add subscript and superscript buttons -->
                                            <button
                                                class="ql-script"
                                                value="sub"
                                            />
                                            <button
                                                class="ql-script"
                                                value="super"
                                            />
                                        </div>
                                    </quill-editor>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
            <b-card>
                <h3>{{ i18nT(`Variables`) }}</h3>
                <p>
                    {{ i18nT(`Variables are the placeholders for the dynamic content that will appear in the message.`) }}
                </p>
                <p>
                    <span class="sk-fixed-label">{{ i18nT(` Candidate first name`) }}</span>
                    [candidate_first_name]<br/>
                    <span class="sk-fixed-label">{{ i18nT(`Candidate last name`) }}</span>
                    [candidate_last_name]<br/>
                    <span class="sk-fixed-label">{{ i18nT(`Candidate full name`) }}</span>
                    [candidate_full_name]<br/>
                    <span class="sk-fixed-label">{{ i18nT(`Candidate email`) }}</span>
                    [candidate_email]<br/>
                    <span class="sk-fixed-label">{{ i18nT(`Position`) }}</span> [position_title]<br/>
                    <span class="sk-fixed-label">{{ i18nT(`Position url`) }}</span>
                    [position_url]<br/>
                    <span class="sk-fixed-label">{{ i18nT(`Company`) }}</span> [company_name]<br/>
                    <span class="sk-fixed-label">{{ i18nT(`Company user first name`) }}</span>
                    [company_user_first_name]<br/>
                    <span class="sk-fixed-label">{{ i18nT(`Company user full name`) }}</span>
                    [company_user_full_name]<br/>
                    <span class="sk-fixed-label">{{ i18nT(`Interview details`) }}</span>
                    [interview_details]
                </p>
            </b-card>
        </b-modal>

        <div v-if="false">
            <b-row
                v-for="(oneMember, index) in members"
                :key="index"
            >
                <b-col sm="12">
                    {{ oneMember.Label }}
                </b-col>
            </b-row>
        </div>

        <b-table
            hover
            :items="members"
            :fields="tableFields"
            show-empty
            :empty-html="emptyHtml"
            ref="refTable"
        >
            <template #cell(Label)="data">
                <h5>
                    {{ data.item.Label }}
                </h5>
            </template>
            <template #cell(Type)="data">
                {{ data.item.Type }}
            </template>
            <template #cell(Subject)="data">
                {{ data.item.Subject }}
            </template>
            <template #cell(Id)="data">
                <b-dropdown
                    variant="link"
                    toggle-class="p-0"
                    no-caret
                >
                    <template #button-content>
                        <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="align-middle text-body"
                        />
                    </template>
                    <b-dropdown-item @click="onEdit(data)">
                        <feather-icon icon="EditIcon"/>
                        <span class="align-middle ml-50">{{ i18nT(`Edit`) }}</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="onDelete(data)">
                        <feather-icon
                            icon="TrashIcon"
                            color="red"
                        />
                        <span
                            class="align-middle ml-50"
                            style="color: red"
                        >{{ i18nT(`Delete`) }}</span>
                    </b-dropdown-item>
                </b-dropdown>
            </template>
        </b-table>
    </b-card>
</template>

<style>
.sk-fixed-label {
    width: 200px;
    display: inline-block;
}
</style>

<script>
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import {
    VBModal,
    BModal,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BTable,
    BDropdown,
    BDropdownItem,
    BFormSelect
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {useInputImageRenderer} from '@core/comp-functions/forms/form-utils'
import {ref} from '@vue/composition-api'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {quillEditor} from 'vue-quill-editor'

export default {
    directives: {
        Ripple,
        'b-modal': VBModal
    },
    components: {
        BModal,
        BButton,
        BForm,
        BFormGroup,
        BFormInput,
        BRow,
        BCol,
        BCard,
        BTable,
        BDropdown,
        BDropdownItem,
        ValidationProvider,
        ValidationObserver,
        BFormSelect,
        quillEditor
    },
    props: {
        generalData: {
            type: Object,
            default: () => {
            }
        }
    },
    data() {
        return {
            editorOption: {
                modules: {
                    toolbar: '#toolbar'
                }
            },
            optionsLocal: [],
            profileFile: null,
            members: [],
            msgTypes: [],
            availableTypes: [],
            tableFields: [
                {
                    key: 'Label',
                    label: 'Label',
                    sortable: true
                },
                {
                    key: 'Type',
                    label: 'Message type'
                },
                {
                    key: 'Subject',
                    label: 'Subject',
                    sortable: true
                },
                {
                    key: 'Id',
                    label: 'Actions'
                }
            ],
            itemForModal: {
                Label: '',
                Type: '',
                Company: null,
                Id: null
            },
            pipelines: [],
        }
    },
    methods: {
        resetForm() {
            this.itemForModal = {
                Label: '',
                LastName: '',
                Company: null,
                Id: null
            }
        },
        validationForm(event) {
            event.preventDefault()
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    const formData = new FormData()
                    formData.append('Label', this.itemForModal.Label)
                    formData.append('Type', this.itemForModal.Type)
                    formData.append('Subject', this.itemForModal.Subject)
                    formData.append('Message', this.itemForModal.Message)

                    if (this.itemForModal.Id) {
                        formData.append('id', this.itemForModal.Id)
                    }

                    this.$http
                        .post('settings/messageTemplates', formData)
                        .then(res => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(res),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })
                            this.loadData()

                            this.$bvModal.hide('modal-messages')
                        })
                        .catch(err => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(err),
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                }
            })
        },
        onDelete({item}) {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, delete it!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(({value}) => {
                if (value) {
                    this.$http
                        .delete(`settings/messageTemplates?id=${item.Id}`)
                        .then(res => {
                            this.loadData()
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(res),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })
                        })
                }
            })
        },
        onEdit(row) {
            this.itemForModal = Object.assign({}, row.item)
            this.pipelines = [
                ...this.availableTypes
                // this.msgTypes.find(e => e.value === row.item.Type)
            ]
            this.$bvModal.show('modal-messages')
        },
        async loadData() {
            // let msgTypes = []
            await this.$http.get(`settings/messageTemplates`).then(({data}) => {
                this.members = data.data.message_templates.filter(
                    template => template !== null
                )
                // msgTypes = this.members.map(m => m.Type)
            })

            await this.$http
                .get(`system/pipeline?pipeline_type=re`)
                .then(({data}) => {
                    this.msgTypes = data.data.map(pipeline => ({
                        value: pipeline.Type,
                        text: pipeline.Label
                    }))
                    this.pipelines = data.data
                        // .filter(e => !msgTypes.includes(e.Type))
                        .map(pipeline => ({
                            value: pipeline.Type,
                            text: pipeline.Label
                        }))
                    this.availableTypes = data.data
                        // .filter(e => !msgTypes.includes(e.Type))
                        .map(pipeline => ({
                            value: pipeline.Type,
                            text: pipeline.Label
                        }))
                })
        }
    },
    setup() {
        const refInputEl = ref(null)
        const previewEl = ref(null)
        const refTable = ref(null)
        const {inputImageRenderer} = useInputImageRenderer(refInputEl, previewEl)

        return {
            refInputEl,
            previewEl,
            required,
            refTable,
            inputImageRenderer
        }
    },
    computed: {
        emptyHtml() {
            return "<img src='" + require('@/assets/images/pages/error.svg') + "' alt='Error page' class='img-fluid' width='300'><br/><br/><h4>" + this.i18nT(`No matching records found`) + "</h4>";
        },
    },
    mounted() {
        this.loadData()
    }
}
</script>
