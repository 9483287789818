<template>
    <b-card>
        <b-row class="mb-2">
            <b-col sm="12">
                <h1>{{ i18nT(`Time off configuration`) }}</h1>
            </b-col>
        </b-row>



        <b-row>
            <b-col sm="12">
                <h3>{{ i18nT(`Paid leave`) }}</h3>
            </b-col>
        </b-row>

        <b-row class="pb-1 mb-2 border-bottom">
            <b-col cols="12">
                <p>{{ i18nT(`Configure the global settings for taking paid leave for your employees. These can be partially overridden on the level of each employee.`) }}</p>
            </b-col>
            <b-col sm="6">
                <b-form-group
                    :label="i18nT(`Set default number of paid leave days per year`)"
                    label-for="Number"
                >
                    <b-form-input
                        min="0"
                        type="number"
                        v-model="AnnualLeave"
                        :placeholder="i18nT(`E.g. 15`)"/>
                </b-form-group>
            </b-col>

            <b-col sm="12">
                <b-form-checkbox
                    name="check-button"
                    class="pt-1"
                    switch
                    v-model="AnnualLeaveInAdvance"
                >
                    {{ i18nT(`Allow employees to take advance holidays`) }}
                </b-form-checkbox>
            </b-col>

            <b-col sm="12">
                <b-form-checkbox
                    name="check-button"
                    class="pt-2"
                    switch
                    v-model="AnnualLeavePortability"
                >
                    {{ i18nT(`Allow employees to carry-over holidays to next year`) }}
                </b-form-checkbox>
            </b-col>

            <b-col sm="12">
                <b-form-checkbox
                    name="check-button"
                    class="pt-2"
                    switch
                    v-model="LeaveResets"
                >
                    {{ i18nT(`Unused leave days from previous year reset each year`) }}
                </b-form-checkbox>
            </b-col>

            <b-col sm="6" class="pt-1">

                <b-form-group
                    :label="i18nT(`Set the date when unused leave days reset`)"
                    label-for="Number"
                    v-if="LeaveResets"
                >
                    <date-picker
                        id="leave-reset-date"
                        v-model="AnnualLeaveResetDate"
                    />
                </b-form-group>
            </b-col>


            <b-col sm="12" class="text-left pt-1">

                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="btn mb-1 mr-1 mt-1 pl-1 btn-primary"
                    variant="primary"
                    @click="saveDays"
                >
                    <feather-icon
                        class="mr-1"
                        icon="CheckIcon"
                    />
                    {{ i18nT(`Save`) }}
                </b-button>

            </b-col>

        </b-row>


        <b-row class="">
            <b-col sm="12">
                <h3>{{ i18nT(`Absence types`) }}</h3>
            </b-col>
        </b-row>

        <b-row class="">
            <b-col sm="6">

                <p>{{ i18nT(`Set up the categories of absence which can be assigned to the requests for time off.`) }}</p>
                <b-button
                    v-b-modal.modal-absence-type
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="mb-1 mr-1 pl-1"
                    variant="primary"
                    @click="resetForm"
                >
                    <feather-icon
                        class="mr-1"
                        icon="PlusCircleIcon"
                    />
                    {{ i18nT(`Add new`) }}
                </b-button>
            </b-col>
        </b-row>
        <draggable
            v-model="absenceTypes"
            class="list-group cursor-move pb-1"
            tag="ul"
            @end="updateOrder"
        >
            <b-list-group-item
                v-for="(documentType, index) in absenceTypes"
                :key="index"
                tag="li"
            >
                <div class="draggable-container">
                    <div>
                        <strong> {{ documentType.Label }}</strong>
                    </div>
                    <div class="d-flex align-items-center">
                        <feather-icon
                            class="cursor-pointer d-sm-block d-none mr-1"
                            icon="Edit2Icon"
                            size="17"
                            @click="onEdit(documentType)"
                        />
                        <feather-icon
                            class="cursor-pointer d-sm-block d-none mr-1"
                            icon="Trash2Icon"
                            size="17"
                            @click="onDelete(documentType)"
                        />
                    </div>
                </div>
            </b-list-group-item>
        </draggable>
        <!-- MODAL -->
        <b-modal
            id="modal-absence-type"
            no-close-on-backdrop
            ok-only
            :ok-title="i18nT(`Save`)"
            :title="i18nT(`Add new Absence Type`)"
            @ok="validationForm"
        >
            <validation-observer ref="simpleRules">
                <b-form class="mt-2">
                    <b-row>
                        <b-col sm="12">
                            <b-form-group
                                :label="i18nT(`Name of the absence type`)"
                                class="required"
                                label-for="Rating name"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="Rating name"
                                    rules="required"
                                >
                                    <b-form-input
                                        v-model="Label"
                                        :placeholder="i18nT(`Sick leave`)"
                                        :state="errors.length > 0 ? false:null"
                                        name="subject"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col sm="12 py-1 d-flex justify-content-between">
                            <b-button
                                v-for="colorOption in ColorOptions"
                                :key="colorOption.value"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                :style="{backgroundColor: colorOption.color + '!important',
                        borderWidth: '3px',
                        borderColor: Colour == colorOption.value ? '#000000!important' : colorOption.color + '!important' }"
                                class="btn-icon rounded-circle"
                                @click="Colour = colorOption.value"
                            />
                        </b-col>
                        <b-col sm="12">
                            <b-form-group
                                :label="i18nT(`Does it discount time`)"
                                class="required"
                                label-for="DiscountTime"
                            >
                                <validation-provider
                                    v-slot="{ errors }"
                                    name="DiscountTime"
                                    rules="required"
                                >
                                    <b-form-select
                                        v-model="DiscountTime"
                                        :options="DiscountTimeOptions"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col sm="12">
                            <b-form-group
                                :label="i18nT(`Needs approval`)"
                                class="required"
                                label-for="NeedApproval"
                            >
                                <validation-provider
                                    v-slot="{ errors }"
                                    name="NeedApproval"
                                    rules="required"
                                >
                                    <b-form-select
                                        v-model="NeedApproval"
                                        :options="NeedApprovalOptions"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col sm="12">
                            <b-form-group>
                                <b-form-checkbox
                                    v-model="AttachmentRequired"
                                    value="1"
                                >
                                    Make attached document mandatory?
                                </b-form-checkbox>
                            </b-form-group>
                        </b-col>
                        <b-col sm="12">
                            <b-form-group
                                :label="i18nT(`Paid?`)"
                                class="required"
                                label-for="Paid"
                            >
                                <validation-provider
                                    v-slot="{ errors }"
                                    name="Paid"
                                    rules="required"
                                >
                                    <b-form-select
                                        v-model="Paid"
                                        :options="PaidOptions"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </b-modal>
    </b-card>
</template>

<script>
import {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BListGroupItem,
    BFormSelect,
    BFormCheckbox
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import draggable from 'vuedraggable'
import {dictToSelectArray} from '@core/utils/utils'
import useAuth from "@/auth/useAuth";
import datePicker from "@core/components/DatePicker.vue";
import Hr_Dashboard from "@/views/dashboard/hr_Dashboard.vue";

export default {
    components: {
        Hr_Dashboard,
        BButton,
        BForm,
        BFormGroup,
        BFormInput,
        BRow,
        BCol,
        BCard,
        ValidationProvider,
        ValidationObserver,
        BListGroupItem,
        draggable,
        BFormSelect,
        BFormCheckbox,
        datePicker
    },
    directives: {
        Ripple
    },
    props: {
        generalData: {
            type: Object,
            default: () => {
            }
        }
    },
    data() {
        return {
            AnnualLeave: 0,
            AnnualLeavePortability: 0,
            AnnualLeaveInAdvance: 0,
            LeaveResets: true,
            AnnualLeaveResetDate: null,


            absenceTypes: [],
            currentType: {},
            types: [],
            currentId: null,
            Label: '',
            DiscountTime: '0',
            NeedApproval: '0',
            AttachmentRequired: '0',
            Colour: 1,
            Paid: '0',
            DiscountTimeOptions: [
                {value: 1, text: this.i18nT(`Yes`)},
                {value: 0, text: this.i18nT(`No, it doesn't discount time`)}
            ],
            NeedApprovalOptions: [
                {value: 1, text: this.i18nT(`Yes`)},
                {value: 0, text: this.i18nT(`No, it's auto-approved`)}
            ],
            PaidOptions: [
                {value: 1, text: this.i18nT(`Yes`)},
                {value: 0, text: this.i18nT(`No`)},
            ],
            ColorOptions: [
                {value: 1, color: '#ff8a81'},
                {value: 2, color: '#c2005b'},
                {value: 3, color: '#dfcf9a'},
                {value: 4, color: '#99c483'},
                {value: 5, color: '#008c37'},
                {value: 6, color: '#37999b'},
                {value: 7, color: '#85c5f2'},
                {value: 8, color: '#3f4fa0'},
                {value: 9, color: '#404d57'},
                {value: 10, color: '#9da6aa'}
            ]
        }
    },
    computed:{
        curWorkspace(){
            return useAuth.getCurWorkspace()
        },
    },
    created() {
        this.loadData()
    },
    methods: {
        loadData() {
            this.$http.get(`hr/absenceTypes`).then(({data}) => {
            this.absenceTypes = data.data.sort((type1, type2) => {
            return type1.Pos - type2.Pos
            })
            this.types = dictToSelectArray(data.data)
        })

        this.$http.get(`settings/timeOff`).then(({data}) => {
            this.AnnualLeave = data.data.AnnualLeave
            this.AnnualLeavePortability = !!data.data.AnnualLeavePortability
            this.AnnualLeaveInAdvance = !!data.data.AnnualLeaveInAdvance
            this.LeaveResets = data.data.AnnualLeaveResetDate != null
            this.AnnualLeaveResetDate = data.data.AnnualLeaveResetDate
        })
        },
        updateOrder() {
        const formData = new FormData()

        formData.append(
            'list',
            this.absenceTypes
            .filter(absenceType => absenceType.Id)
            .map(absenceType => absenceType.Id)
            .join(',')
        )

        this.$http
            .post('settings/savePositions', formData)
            .then(res => {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                title: this.successMessage(res),
                icon: 'InfoIcon',
                variant: 'success'
                }
            })

            })
            .catch(err => {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                title: this.errorMessage(err),
                icon: 'AlertTriangleIcon',
                variant: 'danger'
                }
            })
            })
        },
        saveDays() {
            let formData = new FormData();
            formData.append('AnnualLeave', this.AnnualLeave);
            formData.append('AnnualLeavePortability', this.AnnualLeavePortability  ? 1 : 0);
            formData.append('AnnualLeaveInAdvance', this.AnnualLeaveInAdvance  ? 1 : 0);

            if(this.LeaveResets){
                formData.append('AnnualLeaveResetDate', this.AnnualLeaveResetDate);
            } else {
                formData.append('AnnualLeaveResetDate', "");
            }


            this.$http
                .post('settings/timeOff', formData)
                .then(res => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.successMessage(res),
                            icon: 'InfoIcon',
                            variant: 'success'
                        }
                    })

                    this.loadData()
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.errorMessage(err),
                            icon: 'AlertTriangleIcon',
                            variant: 'danger'
                        }
                    })
                })
        },
        resetForm() {
            this.currentId = null
            ;(this.Label = ''),
                (this.DiscountTime = '0'),
                (this.NeedApproval = '0'),
                (this.AttachmentRequired = '0'),
                (this.Paid = '0')
        },
        validationForm(event) {
            event.preventDefault()

            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    //TODO
                    const formData = new FormData()

                    formData.append('Label', this.Label)
                    formData.append('Colour', this.Colour)
                    formData.append('DiscountTime', parseInt(this.DiscountTime))
                    formData.append('NeedApproval', parseInt(this.NeedApproval))
                    formData.append(
                        'AttachmentRequired',
                        parseInt(this.AttachmentRequired)
                    )
                    formData.append('Paid', parseInt(this.Paid))

                    if (this.currentId) {
                        formData.append('id', this.currentId)
                    }

                    this.$http
                        .post('hr/absenceTypes', formData)
                        .then(res => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(res),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })

                            this.$bvModal.hide('modal-absence-type')
                            this.loadData()
                            this.currentType = {}
                        })
                        .catch(err => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(err),
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                }
            })
        },
        onEdit(type) {
            this.currentId = type.Id
            ;(this.Label = type.Label), (this.DiscountTime = type.DiscountTime)
            this.NeedApproval = type.NeedApproval
            this.AttachmentRequired = type.AttachmentRequired
            this.Colour = type.Colour
            this.Paid = type.Paid

            this.$bvModal.show('modal-absence-type')
        },
        onDelete(type) {
            console.log(type)
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, delete it!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(({value}) => {
                if (value) {
                    this.$http
                        .delete(`hr/absenceTypes?id=${type.Id}`)
                        .then(res => {
                            this.loadData()
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(res),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })
                        })
                        .catch(err => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(err),
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                }
            })
        },
    },
    setup() {
        return {
            required
        }
    },


}
</script>

<style lang="scss">
.draggable-container {
    display: flex;
    justify-content: space-between;
}
</style>
