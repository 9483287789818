<template>
    <b-card>
        <b-row class="mb-2">
            <b-col sm="12">
                <h1>{{ i18nT(`Invoice design`) }}</h1>
            </b-col>
        </b-row>

        <b-row class="pb-1 mb-2 border-bottom">
            <b-col cols="12">
                <p>{{ i18nT(`Configure the global settings for your invoices to best match your company corporate identity and style.`) }}</p>
            </b-col>

            <b-col sm="3">
                <b-form-group
                    :label="i18nT(`Logo position`)"
                    label-for="position"
                >
                    <b-form-select
                        name="position"
                        v-model="logoPosition"
                        :options="positionOptions"
                    >
                    </b-form-select>
                </b-form-group>
            </b-col>

            <b-col sm="3">
                <b-form-group
                    :label="i18nT(`Logo Size`)+ ' (' + logoSize + '%)'"
                    label-for="Number"
                >
                    <b-form-input
                        min="0"
                        max="100"
                        type="range"
                        class="mt-05"
                        v-model="logoSize"
                        :placeholder="i18nT(`E.g. 15`)"/>
                </b-form-group>
            </b-col>

            <b-col sm="6">
                <b-form-group
                    :label="i18nT(`Invoice template`)"
                    label-for="template"
                >
                    <b-form-select
                        name="template"
                        v-model="template"
                        :options="templateOptions"
                    >
                    </b-form-select>
                </b-form-group>

            </b-col>

            <b-col sm="3">
                <b-form-group
                    :label="i18nT(`Primary color`)"
                    label-for="Number"
                >
                    <b-form-input
                        min="0"
                        type="color"
                        v-model="primaryColor"
                        :placeholder="i18nT(`E.g. #C1B2A3`)"/>
                </b-form-group>
            </b-col>

<!--            <b-col sm="3">-->
<!--                <b-form-group-->
<!--                    :label="i18nT(`Color opacity`)+ ' (' + opacity + '%)'"-->
<!--                    label-for="Opacity"-->
<!--                >-->
<!--                    <b-form-input-->
<!--                        min="0"-->
<!--                        max="100"-->
<!--                        type="range"-->
<!--                        class="mt-05"-->
<!--                        v-model="opacity"-->
<!--                        :placeholder="i18nT(`E.g. 15`)"/>-->
<!--                </b-form-group>-->
<!--            </b-col>-->

            <b-col sm="3">
                <b-form-group
                    :label="i18nT(`Font color`)"
                    label-for="fontColor"
                >
                    <b-form-input
                        min="0"
                        type="color"
                        v-model="fontColor"
                        :placeholder="i18nT(`E.g. #C1B2A3`)"/>
                </b-form-group>
            </b-col>

            <b-col sm="3">
                <b-form-group
                    :label="i18nT(`Font size`)+ ' (' + fontSize + 'px)'"
                    label-for="fontColor"
                >
                    <b-form-input
                        min="6"
                        max="24"
                        type="range"
                        class="mt-05"
                        v-model="fontSize"
                        :placeholder="i18nT(`16px`)"/>
                </b-form-group>
            </b-col>

            <b-col sm="3">
                <b-form-group
                    :label="i18nT(`Font family`)"
                    label-for="template"
                >
                    <b-form-select
                        name="template"
                        v-model="font"
                        :options="fontOptions"
                    >
                    </b-form-select>
                </b-form-group>

            </b-col>



            <b-col sm="12" class="text-left pt-1">
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="btn mb-1 mr-1 mt-1 pl-1 btn-primary"
                    variant="primary"
                    @click="saveSettings"
                >
                    <feather-icon
                        class="mr-1"
                        icon="CheckIcon"
                    />
                    {{ i18nT(`Save`) }}
                </b-button>
            </b-col>

        </b-row>


        <b-row class="mb-2">
            <b-col cols="12 text-center">
                <h4 class="mb-0">{{ i18nT(`Invoice design preview`) }}</h4>
                <small class="text-muted">({{ i18nT(`The data in the preview is just for visualization purposes`) }})</small>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <iframe
                    :key="activeIframeUrl"
                    :src="activeIframeUrl"
                    style="width: 100%; height: 1000px; border: none;"></iframe>
            </b-col>
        </b-row>


    </b-card>
</template>

<script>
import {
    BButton,
    //BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    //BListGroupItem,
    BFormSelect,
    //BFormCheckbox
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
//import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
//import {dictToSelectArray} from '@core/utils/utils'
import useAuth from "@/auth/useAuth";
//import datePicker from "@core/components/DatePicker.vue";

export default {
    components: {
        BButton,
        //BForm,
        BFormGroup,
        BFormInput,
        BRow,
        BCol,
        BCard,
        //ValidationProvider,
        //ValidationObserver,
        //BListGroupItem,
        BFormSelect,
        //BFormCheckbox,
        //datePicker
    },
    directives: {
        Ripple
    },
    props: {
        generalData: {
            type: Object,
            default: () => {
            }
        }
    },
    data() {
        return {
            logoSize: 70,
            template: 1,
            templateOptions: [
                {value: 1, text: this.i18nT(`Template 1 (default)`)},
                {value: 2, text: this.i18nT(`Template 2`)},
                // {value: 3, text: this.i18nT(`Template 3`)},
                // {value: 4, text: this.i18nT(`Template 4`)},
            ],

            activeIframeUrl: '',
            waiting: false,
            debounceTimer: null,

            primaryColor: '#3f4fa0',
            fontColor: '#FFF',
            fontSize: 12,
            opacity: 40,

            font: 'arial',
            fontOptions: [
                {value: 'arial', text: `Arial`},
                // {value: 'arial_black', text: this.i18nT(`Arial Black`)},
                {value: 'courier', text: `Courier New`},
                // {value: 'georgia', text: this.i18nT(`Georgia`)},
                // {value: 'impact', text: this.i18nT(`Impact`)},
                // {value: 'lucida Console', text: this.i18nT(`Lucida Console`)},
                // {value: 'lucida_sans', text: this.i18nT(`Lucida Sans Unicode`)},
                // {value: 'palatino', text: this.i18nT(`Palatino Linotype`)},
                // {value: 'tahoma', text: this.i18nT(`Tahoma`)},
                {value: 'times', text: `Times New Roman`},
                // {value: 'trebuchet', text: this.i18nT(`Trebuchet MS`)},
                // {value: 'verdana', text: this.i18nT(`Verdana`)},
            ],

            logoPosition: 'left',
            positionOptions: [
                {value: 'left', text: this.i18nT(`Left`)},
                {value: 'center', text: this.i18nT(`Center`)},
                {value: 'right', text: this.i18nT(`Right`)},
            ],
            ColorOptions: [
                {value: 1, color: '#ff8a81'},
                {value: 2, color: '#c2005b'},
                {value: 3, color: '#dfcf9a'},
                {value: 4, color: '#99c483'},
                {value: 5, color: '#008c37'},
                {value: 6, color: '#37999b'},
                {value: 7, color: '#85c5f2'},
                {value: 8, color: '#3f4fa0'},
                {value: 9, color: '#404d57'},
                {value: 10, color: '#9da6aa'}
            ]
        }
    },
    computed:{
        previewSrc(){
            return this.baseIframeUrl+`web/invoicePreview?h=invoice_sample&preview=1&lang=en&`+
                `logo_size=${this.logoSize}`+
                `&logo_position=${this.logoPosition}`+
                `&var=${this.template}`+
                `&font_color=`+this.urlEncodeColor(this.fontColor)+
                `&font_family=`+this.urlEncodeColor(this.font)+
                `&font_size=`+this.urlEncodeColor(this.fontSize)+
                `&main_color=`+this.urlEncodeColor(this.primaryColor)+
                `&company_id=${this.curWorkspace.Id}`
        },
        curWorkspace(){
            return useAuth.getCurWorkspace()
        },
        baseIframeUrl(){
            return process.env.VUE_APP_API_URL;
        }
    },
    created() {
        this.$http.get(`settings/InvoiceTemplateSettings`).then(({data}) => {

            this.logoSize = data.data.logo_size ? data.data.logo_size : 70;
            this.template = data.data.template ? data.data.template : 1;
            this.primaryColor = data.data.main_color ? data.data.main_color : '#3f4fa0';
            this.fontColor = data.data.font_color? data.data.font_color : '#FFF';
            this.fontSize = data.data.font_size? data.data.font_size : 12;
            this.font = data.data.font_family ? data.data.font_family : 'Arial';
            this.logoPosition = data.data.logo_position ? data.data.logo_position : 'left';
        }).then(() => {
            this.activeIframeUrl = this.previewSrc;
        })

    },
    methods: {
        rgbaToHexOpacity(rgba) {
            // Extract the rgba values using a regular expression
            let matches = rgba.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+\.?\d*))?\)$/);

            if (!matches) {
                throw new Error('Invalid RGBA color format');
            }

            // Parse the rgba values
            let r = parseInt(matches[1]);
            let g = parseInt(matches[2]);
            let b = parseInt(matches[3]);
            let a = matches[4] !== undefined ? parseFloat(matches[4]) : 1;

            // Convert to hex
            let hex = "#" + [r, g, b].map(x => {
                const hex = x.toString(16);
                return hex.length === 1 ? '0' + hex : hex;
            }).join('');

            // Convert alpha to opacity value (0-100 scale)
            let opacity = Math.round(a * 100);

            return { hex, opacity };
        },

        hexToRgba(hex, opacity) {
                // Remove '#' if it's there
                hex = hex.replace('#', '');

                // Parse the hex color
                let r, g, b;

                if (hex.length === 3) { // Short format, e.g., #FFF
                    r = parseInt(hex.charAt(0) + hex.charAt(0), 16);
                    g = parseInt(hex.charAt(1) + hex.charAt(1), 16);
                    b = parseInt(hex.charAt(2) + hex.charAt(2), 16);
                } else if (hex.length === 6) { // Standard format, e.g., #FFFFFF
                    r = parseInt(hex.substr(0, 2), 16);
                    g = parseInt(hex.substr(2, 2), 16);
                    b = parseInt(hex.substr(4, 2), 16);
                } else {
                    throw new Error('Invalid HEX color format');
                }

                // Convert opacity to 0-1 range
                let alpha = opacity / 100;


                // Return the formatted string
                return `rgba(${r}, ${g}, ${b}, ${alpha})`;
            },

        urlEncodeColor(color){
            return encodeURIComponent(color)
        },

        saveSettings() {
            const formData = new FormData()
            formData.append('logo_size', this.logoSize)
            formData.append('logo_position', this.logoPosition)
            formData.append('template', this.template)
            formData.append('main_color', this.primaryColor)
            formData.append('font_color', this.fontColor)
            formData.append('font_family', this.font)
            formData.append('font_size', this.fontSize)

            this.$http
                .post('settings/InvoiceTemplateSettings', formData)
                .then(res => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.successMessage(res),
                            icon: 'InfoIcon',
                            variant: 'success'
                        }
                    })
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.errorMessage(err),
                            icon: 'AlertTriangleIcon',
                            variant: 'danger'
                        }
                    })
                })
        },

    },
    setup() {
        return {
            required
        }
    },
    watch: {
        previewSrc: function (newVal) {
            // Clear the existing timeout if it exists
            if (this.debounceTimer) {
                clearTimeout(this.debounceTimer);
            }

            // Set a new timeout
            this.debounceTimer = setTimeout(() => {
                this.activeIframeUrl = newVal;
            }, 1000); // 1 second delay
        }
    }


}
</script>

<style lang="scss">
.draggable-container {
    display: flex;
    justify-content: space-between;
}
</style>
