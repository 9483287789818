<template>
  <b-card>
    <h4>{{i18nT(`Misc settings`)}}</h4>
    <validation-observer ref="simpleRules">
      <b-form class="mt-2">
        <b-row>
          <b-col sm="12">
            <b-form-group
              :label="i18nT(`Message signature`)"
              label-for="signature"
              class="required"
            >
              <validation-provider
                #default="{ errors }"
                name="signature"
                rules="required"
              >
                <b-form-textarea
                  v-model="signature"
                  :placeholder="i18nT(`Signature`)"
                  name="signature"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              @click.prevent="validationForm"
            >
            {{ i18nT(`Save`) }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
    BButton,
    BForm,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BFormTextarea
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils';
import { ref } from '@vue/composition-api';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      optionsLocal: [],
      profileFile: null,
      countries: [],
      signature: ''
    }
  },
  methods: {
    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData))
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          const formData = new FormData()

          formData.append('signature', this.signature)

          this.$http.post(
            'users/signature', formData
          ).then((res) => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.successMessage(res),
                icon: 'InfoIcon',
                variant: 'success',
              },
            })
          })
          .catch((err) => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.errorMessage(err),
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
        }
      })
    }
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      required,
      inputImageRenderer,
    }
  },
  created() {
    this.$http.get(
      `users/signature`
    ).then(({data}) => {
      this.signature = data.data.signature
    })
  }
}
</script>
