<template>
    <b-card>
        <b-row>
            <b-col sm="12">
                <h4>{{i18nT(`Invoice items`)}}</h4>
                <p>{{ i18nT(`Here, you can predefine default items for use when composing or editing invoices.`) }}</p>
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mb-1 mr-1 pl-1"
                    v-b-modal.modal-invoice-item
                    @click="resetForm"
                >
                    <feather-icon
                        icon="PlusCircleIcon"
                        class="mr-1"
                    />
                    {{i18nT(`Add new`)}}
                </b-button>
            </b-col>
        </b-row>
        <draggable
            v-model="documentTypes"
            tag="ul"
            class="list-group cursor-move"
            @end="updateOrder"
        >
            <b-list-group-item
                v-for="(documentType, index) in documentTypes"
                :key="index"
                tag="li"
            >
                <div class="draggable-container">
                    <div class="d-flex">
                        <div class="main-info">
                            <strong> {{ documentType.Label }}</strong>
                            <div class="font-small-2">
                                <span>{{i18nT(`Unit price`)}}:  {{documentType.Price | money}}</span>
                            </div>
                        </div>

                    </div>

                    <div class="d-flex align-items-center">

                        <div class="variations mr-5">
                            <p class="font-small-2 mb-0">{{i18nT(`VAT`)}}: {{documentType.Vat}}%</p>
                            <p class="font-small-2 mb-0"></p>
                        </div>


                        <feather-icon
                            icon="Edit2Icon"
                            size="17"
                            class="cursor-pointer d-sm-block d-none mr-1"
                            @click="onEdit(documentType)"
                        />
                        <feather-icon
                            icon="Trash2Icon"
                            size="17"
                            class="cursor-pointer d-sm-block d-none mr-1"
                            @click="onDelete(documentType)"
                        />
                    </div>
                </div>
            </b-list-group-item>
        </draggable>

        <!-- MODAL -->
        <b-modal
            id="modal-invoice-item"
            :title="i18nT(`Payment item`)"
            ok-only
            :ok-title="i18nT(`Save`)"
            no-close-on-backdrop
            @ok="validationForm"
        >
            <span>{{ i18nT(`You can create and modify Invoice items to facilitate creating new finance documents.`) }}</span>
            <validation-observer ref="simpleRules">
                <b-form class="mt-2">
                    <b-row>

                        <b-col sm="12">
                            <b-form-group
                                :label="i18nT(`Item name`)"
                                label-for="itemName"
                                class="required"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="name"
                                    rules="required"
                                >
                                    <b-form-input
                                        v-model="itemLabel"
                                        :placeholder="i18nT(`E.g. Accounting services`)"
                                        name="itemName"
                                        :state="errors.length > 0 ? false:null"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col sm="6">
                            <b-form-group
                                :label="i18nT(`Unit price`)"
                                label-for="unitPrice"
                                rules="required"
                            >
                                <b-form-input
                                    v-model="itemPrice"
                                    :placeholder="i18nT(`Unit price`)"
                                    name="unitPrice"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col sm="6">
                            <b-form-group
                                :label="i18nT(`VAT %`)"
                                label-for="itemVAT"
                            >
                                <b-form-input
                                    v-model="itemVAT"
                                    :placeholder="i18nT(`20%`)"
                                    name="itemVAT"
                                />
                            </b-form-group>
                        </b-col>

                    </b-row>
                </b-form>
            </validation-observer>
        </b-modal>
    </b-card>
</template>

<script>
import {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BListGroupItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import draggable from 'vuedraggable'
import _ from 'lodash'

export default {
    components: {

        BButton,
        BForm,
        BFormGroup,
        BFormInput,
        BRow,
        BCol,
        BCard,
        ValidationProvider,
        ValidationObserver,
        BListGroupItem,
        draggable
    },
    directives: {
        Ripple
    },
    props: {
        generalData: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            itemLabel: '',
            itemPrice: '',
            itemVAT: '',
            categoryLabel: '',


            recordTypeOptions: [],
            documentTypes: [],
            currentItem: {},
            typeName: ''
        }
    },
    methods: {
        resetForm() {

            this.currentItem = {}
            this.itemLabel = ''
            this.itemPrice = ''
            this.itemVAT = ''

        },
        validationForm(event) {
            event.preventDefault()

            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    const formData = new FormData()
                    if (!_.isEmpty(this.currentItem)) {
                        formData.append('id', this.currentItem.Id)
                    }
                    formData.append('Label', this.itemLabel)
                    formData.append('Price', this.itemPrice)
                    formData.append('Vat', this.itemVAT)

                    this.$http
                        .post('settings/invoiceItemSuggests', formData)
                        .then(res => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(res),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })

                            this.$bvModal.hide('modal-invoice-item')
                            this.loadData()
                        })
                        .catch(err => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(err),
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                }
            })
        },
        onEdit(item) {
            this.currentItem = item
            this.itemLabel = item.Label
            this.itemPrice = item.Price
            this.itemVAT = item.Vat
            this.$bvModal.show('modal-invoice-item')
        },
        onDelete(type) {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, delete it!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(({ value }) => {
                if (value) {
                    this.$http
                        .delete(`settings/invoiceItemSuggests?id=${type.Id}`)
                        .then(res => {
                            this.loadData()
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(res),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })
                        })
                }
            })
        },
        loadData() {
            this.$http.get(`settings/invoiceItemSuggests`).then(({ data }) => {
                this.documentTypes = data.data.sort((type1, type2) => {
                    return type1.Pos - type2.Pos
                })
            })
        },
        updateOrder() {
            const formData = new FormData()

            formData.append(
                'list',
                this.documentTypes
                    .filter(documentType => documentType.Id)
                    .map(documentType => documentType.Id)
                    .join(',')
            )

            this.$http
                .post('settings/savePositions', formData)
                .then(res => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.successMessage(res),
                            icon: 'InfoIcon',
                            variant: 'success'
                        }
                    })

                    this.$bvModal.hide('modal-invoice-item')
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.errorMessage(err),
                            icon: 'AlertTriangleIcon',
                            variant: 'danger'
                        }
                    })
                })
        }
    },
    setup() {
        return {
            required
        }
    },
    created() {
        this.loadData()
    },
}
</script>

<style lang="scss">
.draggable-container {
    display: flex;
    justify-content: space-between;
}
</style>
