<template>
  <b-card>
    <b-row>
      <b-col sm="12">
        <h1>{{i18nT(`Holidays`)}}</h1>
        <p>{{i18nT(`Set up official holidays and non-working days.`)}}</p>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mb-1 mr-1 pl-1"
          v-b-modal.modal-holidays
          @click="resetForm"
        >
          <feather-icon
            icon="PlusCircleIcon"
            class="mr-1"
          />
          {{i18nT(`Add new`)}}
        </b-button>
      </b-col>
    </b-row>
    <draggable
      v-model="holidays"
      tag="ul"
      class="list-group cursor-move"
    >
      <b-list-group-item
        v-for="(documentType, index) in holidays"
        :key="index"
        tag="li"
      >
        <div class="draggable-container">
          <div>
            <span class="mr-2"> {{ documentType.Day | date}}</span>
            <strong> {{ documentType.Label }}</strong>
          </div>
          <div class="d-flex align-items-center">
            <feather-icon
              icon="Edit2Icon"
              size="17"
              class="cursor-pointer d-sm-block d-none mr-1"
              @click="onEdit(documentType)"
            />
            <feather-icon
              icon="Trash2Icon"
              size="17"
              class="cursor-pointer d-sm-block d-none mr-1"
              @click="onDelete(documentType)"
            />
          </div>
        </div>
      </b-list-group-item>
    </draggable>
    <!-- MODAL -->
    <b-modal
      id="modal-holidays"
      :title="i18nT(`Add new holiday`)"
      ok-only
      ok-title="Save"
      no-close-on-backdrop
      @ok="validationForm"
    >
      <span>{{i18nT(`Add a custom holiday for this office`)}}</span>
      <validation-observer ref="simpleRules">
        <b-form class="mt-2">
          <b-row>

            <b-col sm="12">
              <b-form-group
                :label="i18nT(`Holiday name`)"
                label-for="Holiday name"
                class="required"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Holiday name"
                  rules="required"
                >
                  <b-form-input
                    v-model="currentHoliday.Label"
                    :placeholder="i18nT(`E.g. Chrismas day...`)"
                    name="subject"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group
                :label="i18nT(`Date`)"
                label-for="date"
                class="required"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="date"
                  rules="required"
                >
                  <date-picker
                    reset-button
                    v-model="currentHoliday.Day"
                    :placeholder="i18nT(`From date`)"
                    :start-weekday="1"
                    :locale="`en-US`"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'short',
                      day: '2-digit',
                      weekday: 'short',
                    }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BListGroupItem
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import draggable from 'vuedraggable'
import DatePicker from '@core/components/DatePicker.vue'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BListGroupItem,
    draggable,
    DatePicker
  },
  directives: {
    Ripple
  },
  props: {
    generalData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      holidays: [],
      currentHoliday: {
        Label: '',
        Day: ''
      },
      types: []
    }
  },
  methods: {
    resetForm() {
      this.currentHoliday = {}
    },
    validationForm(event) {
      event.preventDefault()

      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          //TODO
          const formData = new FormData()

          formData.append('Label', this.currentHoliday.Label)
          formData.append('Day', this.currentHoliday.Day)

          if (this.currentHoliday) {
            formData.append('id', this.currentHoliday.Id)
          }

          this.$http
            .post('hr/holidays', formData)
            .then(res => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.successMessage(res),
                  icon: 'InfoIcon',
                  variant: 'success'
                }
              })

              this.$bvModal.hide('modal-holidays')
              this.loadData()
              this.currentHoliday = {}
            })
            .catch(err => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.errorMessage(err),
                  icon: 'AlertTriangleIcon',
                  variant: 'danger'
                }
              })
            })
        }
      })
    },
    onEdit(type) {
      this.currentHoliday = Object.assign({}, type)
      this.$bvModal.show('modal-holidays')
    },
    onDelete(type) {
      this.$swal({
        title: this.i18nT(`Are you sure?`),
        text: this.i18nT(`You won't be able to revert this!`),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.i18nT(`Yes, delete it!`),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(({ value }) => {
        if (value) {
          this.$http.delete(`hr/holidays?id=${type.Id}`).then(res => {
            this.loadData()
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.successMessage(res),
                icon: 'InfoIcon',
                variant: 'success'
              }
            })
          })
        }
      })
    },
    loadData() {
      this.$http.get(`hr/holidays`).then(({ data }) => {
        this.holidays = data.data
      })
    }
  },
  setup() {
    return {
      required
    }
  },
  created() {
    this.loadData()
  }
}
</script>

<style lang="scss">
.draggable-container {
  display: flex;
  justify-content: space-between;
}
</style>
