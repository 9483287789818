<template>
    <b-card>
        <b-row>
            <b-col sm="12">
                <h1>{{ i18nT(`Payment providers`) }}</h1>
                <p>{{ i18nT(`If you have an account with the payment providers listed below you can setup integration with Skileo, allowing you to accept payments for your invoices online.`) }}</p>
            </b-col>
        </b-row>

            <b-list-group-item
                v-for="(provider, index) in providers"
                :key="index"
                tag="li"
            >
                <div class="">
                    <b-row>
                        <b-col cols="3">
                            <div class="sk-toggles d-flex align-items-center" :class="activeProviderClass(provider)">
                                <font-awesome-icon
                                    class="sk-provider-icon mr-1"
                                    :icon="['fab', provider.Icon]"
                                    :size="`2x`"
                                >

                                </font-awesome-icon>
                                <strong> {{ provider.Label }}</strong>
                            </div>

                        </b-col>

                        <b-col cols="4">
                            <div class="sk-toggles d-flex align-items-center">
                                <b-form-checkbox
                                    v-model="provider.Enabled"
                                    switch
                                    :checked="provider.Enabled"
                                    :id="`switch-${provider.Label}`"
                                    :name="`switch-${provider.Label}`"
                                    @change="toggleForInvoice(provider)"
                                    :disabled="!provider.Active"
                                >
                                    {{ i18nT(`Enable for invoices`) }}
                                </b-form-checkbox>
                            </div>
                        </b-col>


                        <b-col cols="2">
                            <div class="sk-toggles d-flex align-items-center">
                                <b-badge
                                    v-if="provider.Status !== 'not_connnected'"
                                    :variant="statusLabelClass(provider)"
                                    class="mr-1">
                                    {{statusLabel(provider)}}
                                </b-badge>
                            </div>
                        </b-col>

                        <b-col cols="3">
                            <div class="sk-toggles d-flex align-items-center float-right">
                            <b-button
                                v-if="provider.Active"
                                variant="outline-primary"
                                class="mr-1"
                                @click="disconnect(provider)" >
                                {{i18nT(`Disconnect`)}}
                            </b-button>
                            <b-button
                                v-else-if="provider.Status === 'pending'"
                                variant="secondary"
                                class="mr-1"
                                @click="connect(provider, index)" >
                                <span class="">{{i18nT(`Reconnect`)}}</span>
                            </b-button>
                                <b-button
                                    v-else
                                    variant="primary"
                                    class="mr-1"
                                    @click="connect(provider, index)" >
                                    <span class="">{{i18nT(`Connect`)}}</span>
                                </b-button>
                        </div>
                        </b-col>

                    </b-row>


                </div>
            </b-list-group-item>

        <!-- MODAL -->
        <b-modal
            id="modal-score-template"
            :title="i18nT(`Rating template`)"
            ok-only
            :ok-title="i18nT(`Save`)"
            no-close-on-backdrop
            @ok="validationForm"
        >
            <span>{{ i18nT(`Create a rating template.`) }}</span>
            <validation-observer ref="simpleRules">
                <b-form class="mt-2">
                    <b-row>

                        <b-col sm="12">
                            <b-form-group
                                :label="i18nT(`Rating name`)"
                                label-for="Rating name"
                                class="required"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="Rating name"
                                    rules="required"
                                >
                                    <b-form-input
                                        v-model="currentType.Label"
                                        :placeholder="i18nT(`E.g. personality, skills, communication...`)"
                                        name="subject"
                                        :state="errors.length > 0 ? false:null"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col sm="12">
                            <b-form-group
                                :label="i18nT(`Rating type`)"
                                label-for="ratingType"
                                class="required"
                            >
                                <validation-provider
                                    v-slot="{ errors }"
                                    name="ratingType"
                                    rules="required"
                                >
                                    <b-form-select
                                        v-model="currentType.Type"
                                        :options="types"
                                        :state="errors.length > 0 ? false:null"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </b-modal>
    </b-card>
</template>

<script>
import {
    BButton,
    //BImg,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BRow,
    BCol,
    BCard,
    BBadge,
    BListGroupItem,
    BFormSelect
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
//import {dictToSelectArray} from '@core/utils/utils'
import {useUtils as useI18Utils} from "@core/libs/i18n";
const {i18n} = useI18Utils();

export default {
    components: {
        BButton,
        //BImg,
        BForm,
        BFormGroup,
        BFormInput,
        BFormCheckbox,
        BRow,
        BCol,
        BCard,
        ValidationProvider,
        ValidationObserver,
        BBadge,
        BListGroupItem,

        BFormSelect
    },
    directives: {
        Ripple
    },
    props: {
        generalData: {
            type: Object,
            default: () => {
            }
        }
    },
    data() {
        return {
            ratingTemplates: [],
            currentType: {},
            types: [],
            providers: [
                // {
                //     Label: "Stripe",
                //     Active: true,
                //     IntegrationUrl: "https://dashboard.stripe.com/register",
                //     Icon: "stripe-s",
                //     Status: "connected",
                //     Enabled: true
                // },
                // {
                //     Label: "PayPal",
                //     Active: false,
                //     IntegrationUrl: "https://dashboard.stripe.com/register",
                //     Icon: "paypal",
                //     Status: "disconnected",
                //     Enabled: false
                // }
            ]
        }
    },
    methods: {

        toggleForInvoice(provider) {
            let formData = new FormData();
            formData.append('payment_provider_id', provider.Id);
            formData.append('enable', provider.Enabled ? 1 : 0);
            this.$http.post('settings/UsePaymentProvider', formData)
                .then(response => {
                    //this.providers[index].Enabled = response.data;
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.successMessage(response),
                            icon: 'InfoIcon',
                            variant: 'success'
                        }
                    })
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.errorMessage(err),
                            icon: 'AlertTriangleIcon',
                            variant: 'danger'
                        }
                    })
                })
        },
        activeProviderClass(provider) {
            return {
                'active-provider': provider.Active
            }
        },
        statusLabel(provider){
            return {
                'connected': i18n(`Connected`),
                'pending': i18n(`Awaiting response`),
                'not_connected': i18n(`Not connected`)
            }[provider.Status]
        },
        statusLabelClass(provider) {
            return {
                'connected': 'success',
                'pending': 'warning',
                'not_connected': 'light-gray'
            }[provider.Status]
        },
        connect(provider, index){
            window.open(provider.ConnectUrl, '_blank');
            this.providers[index].Status = 'pending';
        },
        disconnect(provider){
            let formData = new FormData();
            formData.append('payment_provider_id', provider.Id);
            this.$http.post(`settings/disconnect`, formData);
        },
        resetForm() {
            this.currentType = {}
        },
        validationForm(event) {
            event.preventDefault()

            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    //TODO
                    const formData = new FormData()

                    formData.append('Label', this.currentType.Label)
                    formData.append('Type', this.currentType.Type)

                    if (this.currentType) {
                        formData.append('id', this.currentType.Id)
                    }

                    this.$http
                        .post('settings/ratingTemplates', formData)
                        .then(res => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(res),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })

                            this.$bvModal.hide('modal-score-template')
                            this.loadData()
                            this.currentType = {}
                        })
                        .catch(err => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(err),
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                }
            })
        },
        onEdit(type) {
            this.currentType = Object.assign({}, type)

            this.$bvModal.show('modal-score-template')
        },
        onDelete(type) {
            console.log(type)
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, delete it!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(({value}) => {
                if (value) {
                    this.$http
                        .delete(`settings/ratingTemplates?id=${type.Id}`)
                        .then(res => {
                            this.loadData()
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(res),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })
                        })
                }
            })
        },
        loadData() {
            this.$http.get(`settings/ratingTemplates`).then(({data}) => {
                this.ratingTemplates = data.data
            })
        }
    },
    setup() {
        return {
            required
        }
    },
    created() {
        this.$http.get(`settings/paymentProviders`).then(({data}) => {
            this.providers = data.data.filter(p => p.Available);
           console.log("Providers data: ", data.data);
        })
        this.loadData()
    }
}
</script>

<style lang="scss">

.sk-toggles {
    height: 100%;
}

.sk-provider-icon {
    color: var(--gray);
}

.active-provider .sk-provider-icon {
    color: var(--primary);
}

.draggable-container {
    display: flex;
    justify-content: space-between;
}
</style>
