<template>
    <b-card>
        <b-row>
            <b-col sm="12">
                <h1>{{ i18nT(`Members`) }}</h1>
                <p>{{i18nT(`You can invite staff members and share account access.`)}}</p>
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mb-1 mr-1 pl-1"
                    @click="onAdd()"
                    v-b-modal.modal-1
                >
                    <feather-icon
                        icon="PlusCircleIcon"
                        class="mr-1"
                    />
                    {{ i18nT(`Add new`) }}
                </b-button>
            </b-col>
        </b-row>
        <b-modal
            id="modal-1"
            :title="i18nT(`Members`)"
            ok-only
            :ok-title="i18nT(`Save`)"
            no-close-on-backdrop
            @ok="saveStaff"
            @hidden="resetForm"
        >
            <span>{{ i18nT(`Create staff members and share account access.`) }}</span>
            <validation-observer ref="simpleRules">
                <b-form class="mt-2">
                    <b-row>
                        <b-col sm="6">
                            <b-form-group
                                :label="i18nT(`First name`)"
                                label-for="firstname"
                                class="required"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="First Name"
                                    rules="required"
                                >
                                    <b-form-input
                                        v-model="itemForModal.FirstName"
                                        name="firstname"
                                        :readonly="!!itemForModal.Id"
                                        :placeholder="i18nT(`First Name`)"
                                        :state="errors.length > 0 ? false:null"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col sm="6">
                            <b-form-group
                                :label="i18nT(`Last name`)"
                                label-for="lastName"
                                class="required"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="Last Name"
                                    rules="required"
                                >
                                    <b-form-input
                                        v-model="itemForModal.LastName"
                                        name="lastName"
                                        :readonly="!!itemForModal.Id"
                                        :placeholder="i18nT(`Last Name`)"
                                        :state="errors.length > 0 ? false:null"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <b-col sm="12">
                            <!-- email -->
                            <b-form-group
                                :label="i18nT(`Email`)"
                                label-for="email"
                                class="required"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="Email"
                                    rules="required"
                                >

                                    <b-form-input
                                        v-model="itemForModal.Email"
                                        :placeholder="i18nT(`Email`)"
                                        name="email"
                                        :readonly="!!itemForModal.Id"
                                        :state="errors.length > 0 ? false:null"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                            <b-form-group
                                label-for="createEmployee"
                                class="mb-2 mt-2"
                                v-if="!itemForModal.Id"
                            >

                                <b-form-checkbox
                                    :checked="itemForModal.createEmployee"
                                    v-model="itemForModal.createEmployee"
                                    name="check-button"
                                    switch
                                    inline

                                >
                                    {{ i18nT(`Also create an employee account for this member`) }}
                                </b-form-checkbox>

                            </b-form-group>
                        </b-col>

                        <b-col sm="12">
                                <h4>{{i18nT(`Access rights`)}}</h4>
                                <span>{{ i18nT(`Configure access rights for each software module.`) }}</span>

                                <app-collapse
                                    id="software-permissions"
                                    type="margin"
                                    class="mt-2"
                                >

                                    <app-collapse-item
                                        v-for="( soft,indexS) in modalPermissions"
                                        :title="i18nT(soft.label)"
                                        :key="indexS"
                                    >
                                        <b-row>
                                            <b-col sm="3" offset-sm="6">
                                                <strong>{{i18nT(`Read`)}}</strong>
                                            </b-col>
                                            <b-col sm="3">
                                                <strong>{{i18nT(`Write`)}}</strong>
                                            </b-col>
                                        </b-row>
                                        <b-row
                                            v-for="(module, indexM) in soft.modules"
                                            :key="indexM"
                                            label-for="permissions"
                                            class="border-bottom pb-05 pt-05 row"
                                        >
                                            <b-col sm="6">
                                                <span class="d-inline-flex text-bold">{{ i18nT(module.label) }}</span>
                                            </b-col>
                                            <b-col sm="3">
                                                <div class="d-inline-flex flex-wrap">
                                                    <b-form-checkbox
                                                        :checked="module.acl.read"
                                                        v-model="modalPermissions[indexS].modules[indexM].acl.read"
                                                        name="check-button"
                                                        switch
                                                        inline
                                                        @change="changeRead(indexS,indexM)"
                                                    >
                                                    </b-form-checkbox>
                                                </div>
                                            </b-col>
                                            <b-col sm="3">
                                                <div class="d-inline-flex">
                                                    <b-form-checkbox
                                                        :checked="module.acl.write"
                                                        v-model="modalPermissions[indexS].modules[indexM].acl.write"
                                                        name="check-button"
                                                        switch
                                                        inline
                                                        @change="changeWrite(indexS,indexM)"
                                                    >
                                                    </b-form-checkbox>
                                                </div>
                                            </b-col>
                                        </b-row>
                                    </app-collapse-item>
                                </app-collapse>

                        </b-col>

                    </b-row>

                </b-form>

            </validation-observer>
        </b-modal>

        <b-table
            hover
            :items="members"
            :fields="memberFields"
            :empty-html="emptyHtml"
            show-empty
        >
            <template #cell(Label)="data">
                <h5>
                    {{ data.item.Label }}
                </h5>
            </template>
            <template #cell(Email)="data">
                {{ data.item.Email }}
            </template>
            <template #cell(inviteStatus)="data">
                <span class="badge badge-pill is-uppercase" :class="data.item.PublicationStatus == 'ACTIVE' ? 'badge-light-success' : 'badge-light-warning' ">
                    {{ data.item.PublicationStatus }}
                </span>
            </template>
            <template #cell(Id)="data">
                <b-dropdown
                    variant="link"
                    toggle-class="p-0"
                    no-caret
                >
                    <template #button-content>
                        <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="align-middle text-body"
                        />
                    </template>
                    <b-dropdown-item @click="onEdit(data)">
                        <feather-icon icon="EditIcon"/>
                        <span class="align-middle ml-50">{{ i18nT(`Edit`) }}</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="onDelete(data)">
                        <feather-icon
                            icon="TrashIcon"
                            color="red"
                        />
                        <span
                            class="align-middle ml-50"
                            style="color: red"
                        >{{ i18nT(`Delete`) }}</span>
                    </b-dropdown-item>
                </b-dropdown>
            </template>

        </b-table>

    </b-card>
</template>


<style scoped>
.pb-05 {
    padding-bottom: 5px;
}
.pt-05 {
    padding-top: 5px;
}
</style>

<script>
import {
    VBModal,
    BModal,
    //BFormFile,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BRow,
    BCol,
    //BAlert,
    BCard,
    BTable,
    BDropdown,
    BDropdownItem,

    //BCardText,
    // BMedia,
    // BMediaAside,
    // BMediaBody,
    // BLink,
    // BImg,
    // BFormSelect
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {useInputImageRenderer} from '@core/comp-functions/forms/form-utils'
import {ref} from '@vue/composition-api'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
//import { dictToSelectArray } from '@core/utils/utils';
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useAuth from '@/auth/useAuth'

import {useUtils as useI18Utils} from "@core/libs/i18n";
const {i18n} = useI18Utils();


export default {
    directives: {
        Ripple,
        'b-modal': VBModal
    },
    components: {
        AppCollapse,
        AppCollapseItem,
        BModal,
        BButton,
        BForm,
        // BImg,
        // BFormFile,
        BFormGroup,
        BFormInput,
        BFormCheckbox,

        BRow,
        BCol,
        // BAlert,
        BCard,
        BTable,
        // BCardText,
        // BMedia,
        // BMediaAside,
        // BMediaBody,
        // BLink,
        // BFormSelect,
        BDropdown,
        BDropdownItem,
        ValidationProvider,
        ValidationObserver,
    },
    props: {
        generalData: {
            type: Object,
            default: () => {
            }
        }
    },
    data() {
        const workspaceOptions = useAuth.getWorkspaces().map(workspace => ({
            value: workspace.Id,
            title: workspace.Label
        }))
        const softwares = useAuth.getSoftwares()

        return {
            optionsLocal: [],
            profileFile: null,
            disModules : [
                {soft: 'common',
                    mods: [
                        'tasks',
                        'documents',
                        'calendar',
                        'personnal_data',
                        'financial_data',
                        'contact_data'
                    ]
                },
                {soft: 'on',
                    mods: [
                        'offboarding',
                        'onboarding',]
                },
                {soft: 'hr',
                    mods: [
                        'leave_absence',
                        'timesheets',]
                },
                {soft: 'en',
                    mods: [
                        'suggestions',
                        'surveys',]
                }
            ],
            memberFields: [
                {
                    key: 'Label',
                    label: i18n(`Names`),
                    sortable: true
                },
                {
                    key: 'Email',
                    label: i18n(`Email`),
                    sortable: true
                },
                {
                    key: 'inviteStatus',
                    label: i18n(`Invitation status`)
                },
                {
                    key: 'Id',
                    label: i18n(`Actions`),
                    class: 'tbl-actions'
                }
            ],
            itemForModal: {
                FirstName: '',
                LastName: '',
                Id: null,
                permissions: null,
                createEmployee: 0,
            },
            members: [],
            workspaceOptions,
            softwares,
            modalPermissions: [],
            modalCommonPermissions: [
                {
                    label: i18n(`Common`),
                    short: 'co',
                    acl : {
                        read: true,
                        write: true,
                    },
                    modules: [
                        {
                            label: i18n(`Tasks`),
                            modId: 1,
                            acl: {
                                read: true,
                                write: true,
                            }
                        },
                        {
                            label: i18n(`Documents`),
                            modId: 2,
                            acl: {
                                read: true,
                                write: true,
                            }
                        },
                        {
                            label: i18n(`Calendar`),
                            modId: 3,
                            acl: {
                                read: true,
                                write: true,
                            }
                        },
                        {
                            label: i18n(`Messages`),
                            modId: 4,
                            acl: {
                                read: true,
                                write: true,
                            }
                        },
                        {
                            label: i18n(`Organization profile`),
                            modId: 5,
                            acl: {
                                read: true,
                                write: true,
                            }
                        }
                    ],
                },
            ],
            permissions: [],
            warnMessages: {
                employees_read_off:
                        i18n("The following modules are interconnected and write permission will be deactivated:\n") +
                        "<ul class='text-left sk-warn-mod-list'>"+
                            "<li>"+i18n(`Tasks`)+"</li>" +
                            "<li>"+i18n(`Documents`)+"</li>" +
                            "<li>"+i18n(`Calendar`)+"</li>" +
                            "<li>"+i18n(`Onboarding`)+"</li>" +
                            "<li>"+i18n(`Offboarding`)+"</li>"+
                            "<li>"+i18n(`Time off`)+"</li>" +
                            "<li>"+i18n(`Timesheets`)+"</li>"+
                            "<li>"+i18n(`Surveys`)+"</li>" +
                            "<li>"+i18n(`Suggestions`)+"</li>"+
                        "</ul>",
                employees_write_off: i18n("The following modules are interconnected and write permission will be deactivated:\n") +
                        "<ul class='text-left sk-warn-mod-list'>"+
                            "<li>"+i18n(`Onboarding`)+"</li>" +
                            "<li>"+i18n(`Offboarding`)+"</li>"+
                        "</ul>",
                all_read_on: i18n(`The following module is interconnected and read permission will be activated:`) +
                                    "<ul class='text-left sk-warn-mod-list'>" +
                                        "<li>"+i18n(`Employees`)+"</li>"+
                                    "</ul>",
                all_write_on: i18n(`The following module is interconnected and read and write permissions will be activated:`) +
                                    "<ul class='text-left sk-warn-mod-list'>" +
                                    "<li>"+i18n(`Employees`)+"</li>"+
                                    "</ul>",
            },
        }
    },
    methods: {
        getDefaultPermissions(){
          this.$http.get('/settings/acl').then(response => {
            this.modalPermissions = response.data;
            this.permissions = response.data;
          });
        },
        changeWrite(indexS, indexM, common = false){
            let permissions = common ? this.modalCommonPermissions : this.modalPermissions
            let readBefore = permissions[indexS].modules[indexM].acl.read;

            //TURNING WRITE ON
            if(permissions[indexS].modules[indexM].acl.write){
                permissions[indexS].modules[indexM].acl.read = true;

             //CHECK IF IT IS A MODULE REQUIRING EMPLOYEES READ & WRITE
              if(this.criticalModules.indexOf(indexM) !== -1 &&
                  !permissions.common.modules.employees.acl.write
              ){
                  this.$swal({
                      html: this.warnMessages.all_write_on,
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonText: 'Confirm',
                      customClass: {
                          confirmButton: 'btn btn-primary',
                          cancelButton: 'btn btn-outline-danger ml-1',
                      },
                      buttonsStyling: false,
                  }).then( result => {
                      if (result.isConfirmed) {
                          permissions.common.modules.employees.acl.write = true;
                          permissions.common.modules.employees.acl.read = true;
                      }else {
                          permissions[indexS].modules[indexM].acl.read = readBefore;
                          permissions[indexS].modules[indexM].acl.write = false;
                      }
                  })
              }
          }

          //TURNING EMPLOYEE WRITE OFF
          if(!permissions[indexS].modules[indexM].acl.write && indexM == "employees") {

              this.$swal({
                  html: this.warnMessages.employees_write_off,
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonText: this.i18nT(`Confirm`),
                  customClass: {
                      confirmButton: 'btn btn-primary',
                      cancelButton: 'btn btn-outline-danger ml-1',
                  },
                  buttonsStyling: false,
              }).then( result => {
                  if (result.isConfirmed) {
                      permissions.on.modules.offboarding.acl.write = false;
                      permissions.on.modules.onboarding.acl.write = false;
                  }else {
                      permissions[indexS].modules[indexM].acl.read = readBefore;
                      permissions[indexS].modules[indexM].acl.write = true;
                  }
              })




          }
        },
        changeRead(indexS, indexM, common = false){
          let self = this;
          let permissions = common ? this.modalCommonPermissions : this.modalPermissions
          let writeBefore = permissions[indexS].modules[indexM].acl.write;

          if(permissions[indexS].modules[indexM].acl.read) {
              if(this.criticalModules.indexOf(indexM) !== -1 &&
                  !permissions.common.modules.employees.acl.read
              ){
                  this.$swal({
                      //title: "Are you sure you want to disable read access to Employees?",
                      html: this.warnMessages.all_read_on,
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonText: 'Confirm',
                      customClass: {
                          confirmButton: 'btn btn-primary',
                          cancelButton: 'btn btn-outline-danger ml-1',
                      },
                      buttonsStyling: false,
                  }).then( result => {
                      if (result.isConfirmed) {
                          permissions.common.modules.employees.acl.read = true;
                      }else {
                          permissions[indexS].modules[indexM].acl.read = false;
                      }
                  })
              }
          }


          if(!permissions[indexS].modules[indexM].acl.read){
              permissions[indexS].modules[indexM].acl.write = false
              //Turning WRITE permissions OFF for a module
              if(indexM == "employees"){
                  this.$swal({
                      //title: "Are you sure you want to disable read access to Employees?",
                      html: this.warnMessages.employees_read_off,
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonText: 'Confirm',
                      customClass: {
                          confirmButton: 'btn btn-primary',
                          cancelButton: 'btn btn-outline-danger ml-1',
                      },
                      buttonsStyling: false,
                  }).then(  result => {
                      if (result.isConfirmed) {
                          self.disableReadModules();
                      }else{
                          permissions[indexS].modules[indexM].acl.write = writeBefore;
                          permissions[indexS].modules[indexM].acl.read = true;
                      }
                  })
              }
          }
        },
        disableReadModules(){
            //console.log(this.modalPermissions);

            this.disModules.forEach(soft => {
                soft.mods.forEach(mod => {
                    this.modalPermissions[soft.soft].modules[mod].acl.read = false;
                    this.modalPermissions[soft.soft].modules[mod].acl.write = false;
                })
            })
        },
        resetForm() {
            this.itemForModal = {
                FirstName: '',
                LastName: '',
                Id: null,
                Email: '',
            }
            this.modalPermissions = {...this.permissions}
        },
        resetEditForm() {
            this.selectedWorkspaces = []
        },
        validationRestrictForm(form) {
            form.preventDefault()
            this.$refs.restrictRules.validate().then(success => {
                if (success) {
                    const formData = new FormData()

                    formData.append('member_id', this.itemForModal.Id)

                    this.$http
                        .post('users/member', formData)
                        .then(res => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(res),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })
                            this.$bvModal.hide('modal-2')
                            this.onRefresh()
                        })
                        .catch(err => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(err),
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                }
            })
        },
        saveStaff(event) {
            event.preventDefault()
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    const formData = new FormData()

                    formData.append('first_name', this.itemForModal.FirstName)
                    formData.append('last_name', this.itemForModal.LastName)
                    formData.append('email', this.itemForModal.Email)
                    if(!this.itemForModal.Id){
                        formData.append('createEmployee', this.itemForModal.createEmployee)
                    }

                    if(this.itemForModal.Id){
                        formData.append('id', this.itemForModal.Id)
                    }
                    formData.append('permissions',  JSON.stringify(this.modalPermissions));

                    this.$http
                        .post('users/member', formData)
                        .then(res => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(res),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                },
                                duration: 2000
                            })
                            this.resetForm()
                            this.onRefresh()
                            this.$bvModal.hide('modal-1')
                        })
                        .catch(err => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(err),
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                }
            })
        },
        onDelete(item) {
            this.$swal({
                title: i18n(`Are you sure?`),
                text: i18n(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: i18n(`Yes, delete it!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(({value}) => {
                if (value) {
                    this.$http
                        .delete(`users/member?member_id=${item.value}`)
                        .then(res => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(res),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })
                            this.onRefresh()
                        })
                }
            })
        },
        onAdd() {
            this.resetForm()
            this.$bvModal.show('modal-1')
        },
        onEdit(row) {
            this.itemForModal = row.item
            this.modalPermissions = this.itemForModal.Permissions;
            console.log(this.modalPermissions);
            this.$bvModal.show('modal-1')
        },
        onRefresh() {
            this.$http.get(`settings/members`).then(({data}) => {
                this.members = data.data
                    .filter(item => item !== null)
                    .map(oneMember => ({
                        ...oneMember,
                        ...{
                            inviteStatus: oneMember.Invited === 1 ? 'Invited' : 'Not Invited'
                        }
                    }))
            });
            this.getDefaultPermissions();
        }
    },
    setup() {
        const refInputEl = ref(null)
        const previewEl = ref(null)

        const {inputImageRenderer} = useInputImageRenderer(refInputEl, previewEl)

        return {
            refInputEl,
            previewEl,
            required,
            inputImageRenderer
        }
    },
    mounted() {
        this.onRefresh()
    },
    computed: {
        criticalModules(){
            let mods = [];
            this.disModules.forEach(soft => {
                soft.mods.forEach(mod => {
                    mods.push(mod);
                })
            });
            return mods;
        },
        emptyHtml() {
            return "<img src='" + require('@/assets/images/pages/error.svg') + "' alt='Error page' class='img-fluid' width='300'><br/><br/><h4>" + this.i18nT(`No matching records found`) + "</h4>";
        },
    }
}
</script>


<style>
ul.sk-warn-mod-list {
    list-style: circle;
    column-count: 2;
    padding-top: 20px;
    padding-inline-start: 25px;
}
</style>
