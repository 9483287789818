<template>
  <b-card>
    <b-row>
      <b-col sm="12">
        <h1>{{i18nT(`Recruiting pipeline`)}}</h1>
        <p>{{i18nT(`Configure your recruiting pipeline. Drag and drop existing stages to change the order or add new ones.`)}}</p>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mb-1 mr-1 pl-1"
          v-b-modal.modal-pipeline
          @click="resetForm"
        >
          <feather-icon
            icon="PlusCircleIcon"
            class="mr-1"
          />
          {{i18nT(`Add new`)}}
        </b-button>
      </b-col>
    </b-row>
    <draggable
      v-model="pipelines"
      tag="ul"
      class="list-group cursor-move"
      @end="updateOrder"
    >
      <b-list-group-item
        v-for="(documentType, index) in pipelines"
        :key="index"
        tag="li"
      >
        <div class="draggable-container">
          <div>
            <strong> {{ documentType.Label }}</strong>
          </div>
          <div class="d-flex align-items-center">
            <feather-icon
              v-if="documentType.Deletable == 1"
              icon="Trash2Icon"
              size="17"
              class="cursor-pointer d-sm-block d-none mr-1"
              @click="onDelete(documentType)"
            />

            <feather-icon
              icon="Edit2Icon"
              size="17"
              class="cursor-pointer d-sm-block d-none mr-1"
              @click="onEdit(documentType)"
            />
          </div>
        </div>
      </b-list-group-item>
    </draggable>

    <!-- MODAL -->
    <b-modal
      id="modal-pipeline"
      title="New / edit stage"
      ok-only
      :ok-title="i18nT(`Save`)"
      no-close-on-backdrop
      @ok="validationForm"
    >
      <span>{{i18nT(`Create / edit stages in your pipeline.`)}}</span>
      <validation-observer ref="simpleRules">
        <b-form class="mt-2">
          <b-row>
            <b-col sm="12">
              <b-form-group
                :label="i18nT(`Stage Name`)"
                label-for="pipelineName"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Stage Name"
                  rules="required"
                >
                  <b-form-input
                    v-model="pipelineName"
                    :placeholder="i18nT(`E.g. interview template`)"
                    name="subject"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="12" v-if="currentType.Deletable && currentType.Deletable === 1 || !currentType.Label">
              <b-form-group
                :label="i18nT(`Stage type`)"
                label-for="stageType"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Stage type"
                  rules="required"
                >
                  <b-form-select
                    v-model="stageType"
                    label="title"
                    :options="stageTypes"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BListGroupItem,
  BFormSelect
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import draggable from 'vuedraggable'
import { dictToSelectArray } from '@core/utils/utils'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BListGroupItem,
    draggable,
    BFormSelect
  },
  directives: {
    Ripple
  },
  props: {
    generalData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      pipelines: [],
      currentType: {},
      pipelineName: '',
      stageTypes: [],
      availableTypes: [],
      stageType: ''
    }
  },
  methods: {
    resetForm() {
      this.currentType = {}
      this.pipelineName = ''
      this.stageType = ''
    },
    validationForm(event) {
      event.preventDefault()

      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          //TODO
          const formData = new FormData()
          formData.append('Label', this.pipelineName)
          formData.append('Type', this.stageType)
          if (this.currentType) {
            formData.append('id', this.currentType.Id)
          }
          this.$http
            .post('settings/pipelineStages', formData)
            .then(res => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.successMessage(res),
                  icon: 'InfoIcon',
                  variant: 'success'
                }
              })
              this.loadData()
              this.$bvModal.hide('modal-pipeline')
            })
            .catch(err => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.errorMessage(err),
                  icon: 'AlertTriangleIcon',
                  variant: 'danger'
                }
              })
            })
        }
      })
    },
    onEdit(type) {
      this.currentType = type
      this.pipelineName = type.Label
      this.stageType = type.Type

      this.$bvModal.show('modal-pipeline')
    },
    onDelete(pipeline) {
      this.$swal({
        title: this.i18nT(`Are you sure?`),
        text: this.i18nT(`You won't be able to revert this!`),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.i18nT(`Yes, delete it!`),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(({ value }) => {
        if (value) {
          this.$http
            .delete(`settings/pipelineStages?id=${pipeline.Id}`)
            .then(res => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.successMessage(res),
                  icon: 'InfoIcon',
                  variant: 'success'
                }
              })
              this.loadData()
            })
            .catch(err => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.errorMessage(err),
                  icon: 'AlertTriangleIcon',
                  variant: 'danger'
                }
              })
            })
        }
      })
    },
    loadData() {
      this.$http.get(`system/pipeline?pipeline_type=re`).then(({ data }) => {
        this.pipelines = data.data.sort((type1, type2) => {
          return type1.Pos - type2.Pos
        })
        // const existingTypes = this.pipelines.map(e => e.Short)
        this.stageTypes = this.availableTypes
      })
    },
    updateOrder() {
      const formData = new FormData()

      formData.append(
        'list',
        this.pipelines
          .filter(pipeline => pipeline.Id)
          .map(pipeline => pipeline.Id)
          .join(',')
      )

      this.$http
        .post('settings/savePositions', formData)
        .then((res) => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.successMessage(res),
              icon: 'InfoIcon',
              variant: 'success'
            }
          })

          this.$bvModal.hide('modal-pipeline')
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.errorMessage(err),
              icon: 'AlertTriangleIcon',
              variant: 'danger'
            }
          })
        })
    }
  },
  setup() {
    return {
      required
    }
  },
  created() {
    this.$http.get(`system/rePipelineTypes`).then(({ data }) => {
      this.availableTypes = dictToSelectArray(data.data)
      this.loadData()
    })
  }
}
</script>

<style lang="scss">
.draggable-container {
  display: flex;
  justify-content: space-between;
}
</style>
