<template>
    <b-card>
        <b-row>
            <b-col sm="12">
                <h4>{{i18nT(`Invoice series`)}}</h4>
                <p>{{ i18nT(`Configure the invoice series that would be made available for automatic invoice numbering.`) }}</p>
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mb-1 mr-1 pl-1"
                    v-b-modal.modal-invoice-series
                    @click="resetForm"
                >
                    <feather-icon
                        icon="PlusCircleIcon"
                        class="mr-1"
                    />
                    {{i18nT(`Add new`)}}
                </b-button>
            </b-col>
        </b-row>
        <draggable
            v-model="documentTypes"
            tag="ul"
            class="list-group cursor-move"
            @end="updateOrder"
        >
            <b-list-group-item
                v-for="(documentType, index) in documentTypes"
                :key="index"
                tag="li"
            >
                <div class="draggable-container">
                    <div class="d-flex">
                        <div class="main-info">
                            <strong> {{ documentType.Label }}</strong>
                            <div class="font-small-2">
                                <span>{{recordTypeLabel(documentType.RecordType)}}</span>
                            </div>
                        </div>

                    </div>

                    <div class="d-flex align-items-center justify-content-between">

                        <div class="variations mr-5" style="width:130px;">
                            <p class="font-small-2 mb-0 text-truncate" v-b-tooltip.hover :title="documentType.Prefix">{{i18nT(`Series prefix`)}}:{{documentType.Prefix}}</p>
                            <p class="font-small-2 mb-0 text-truncate">{{i18nT(`Next in series`)}}:  {{documentType.StartAt}}</p>
                        </div>


                        <feather-icon
                            icon="Edit2Icon"
                            size="17"
                            class="cursor-pointer d-sm-block d-none mr-1"
                            :class="{'text-muted': documentType.IsSystem}"
                            @click="onEdit(documentType)"
                        />
                        <feather-icon
                            icon="Trash2Icon"
                            size="17"
                            class="cursor-pointer d-sm-block d-none mr-1"
                            :class="{'text-muted': documentType.IsSystem}"
                            @click="onDelete(documentType)"
                        />
                    </div>
                </div>
            </b-list-group-item>
        </draggable>

        <!-- MODAL -->
        <b-modal
            id="modal-invoice-series"
            :title="i18nT(`Document series`)"
            ok-only
            :ok-title="i18nT(`Save`)"
            no-close-on-backdrop
            @ok="validationForm"
        >
            <span>{{ i18nT(`You can create and modify invoice, credit and debit note series which you can assign to your financial documents here.`) }}</span>
            <validation-observer ref="seriesValidator">
                <b-form class="mt-2">
                    <b-row>

                        <b-col sm="12">
                            <b-form-group
                                :label="i18nT(`Series name`)"
                                label-for="seriesName"
                                class="required"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="seriesName"
                                    rules="required"
                                >
                                    <b-form-input
                                        v-model="seriesLabel"
                                        :placeholder="i18nT(`E.g. primary`)"
                                        name="subject"
                                        :state="errors.length > 0 ? false:null"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col sm="6">

                            <b-form-group
                                :label="i18nT(`Series prefix`)"
                                label-for="invoicePrefix"
                            >
                                <b-form-input
                                    v-model="seriesPrefix"
                                    :placeholder="i18nT(`E.g. INV`)"
                                    name="invoicePrefix"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col sm="6">

                            <b-form-group
                                :label="i18nT(`Next in series at`)"
                                label-for="invoiceStart"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="invoiceStart"
                                    rules="required"
                                >
                                    <b-form-input
                                        v-model="seriesStartAt"
                                        :placeholder="i18nT(`E.g. 175`)"
                                        name="invoiceStart"
                                        :state="errors.length > 0 ? false:null"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col sm="12">
                            <b-form-group
                                :label="i18nT(`Available for document type`)"
                                label-for="recordType"
                            >
                                <b-form-select
                                    v-model="categoryRecordType"
                                    :options="recordTypeOptions"
                                />
                            </b-form-group>
                        </b-col>

                    </b-row>
                </b-form>
            </validation-observer>
        </b-modal>
    </b-card>
</template>

<script>
import {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BListGroupItem,
    //BFormCheckbox,
    BFormSelect,
    VBTooltip
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import draggable from 'vuedraggable'
import _ from 'lodash'
//import axiosIns from "@/libs/axios";
import {dictToSelectArray} from "@core/utils/utils";

export default {
    components: {
        BFormSelect,
        //BFormCheckbox,
        BButton,
        BForm,
        BFormGroup,
        BFormInput,
        BRow,
        BCol,
        BCard,
        ValidationProvider,
        ValidationObserver,
        BListGroupItem,
        draggable
    },
    directives: {
        Ripple,
        'b-tooltip': VBTooltip
    },
    props: {
        generalData: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            seriesLabel: '',
            seriesPrefix: '',
            seriesStartAt: '',
            categoryType: 1,
            categoryNonTaxable: false,
            categoryHasVAT: false,
            categoryRecordType: 1,
            // typeOptions: [
            //     { text: this.i18nT(`Income`), value: 1 },
            //     { text: this.i18nT(`Expenses`), value: 2 }
            // ],
            recordTypeOptions: [],
            documentTypes: [],
            currentType: {},
            typeName: ''
        }
    },
    methods: {
        recordTypeLabel(value) {
            const recordType = this.recordTypeOptions.find(item => item.value == value)
            return recordType ? recordType.text : ''
        },
        resetForm() {
            this.currentType = {}
            this.seriesLabel = ''
            this.seriesPrefix = ''
            this.seriesStartAt = ''
            this.categoryRecordType = 1

        },
        validationForm(event) {
            event.preventDefault()

            this.$refs.seriesValidator.validate().then(success => {
                if (success) {
                    const formData = new FormData()
                    if (!_.isEmpty(this.currentType)) {
                        formData.append('id', this.currentType.Id)
                    }
                    formData.append('Label', this.seriesLabel)
                    formData.append('Prefix', this.seriesPrefix)
                    formData.append('StartAt', this.seriesStartAt)
                    formData.append('RecordType', this.categoryRecordType)

                    this.$http
                        .post('settings/invoiceSeries', formData)
                        .then(res => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(res),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })

                            this.$bvModal.hide('modal-invoice-series')
                            this.loadData()
                        })
                        .catch(err => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(err),
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                }
            })
        },
        onEdit(type) {

            if(type.IsSystem){
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: this.i18nT(`This is the system default invioce series can not be edited.`),
                        icon: 'AlertTriangleIcon',
                        variant: 'danger'
                    }
                })
                return
            }

            this.currentType = type
            this.seriesLabel = type.Label
            this.seriesPrefix = type.Prefix
            this.seriesStartAt = type.StartAt
            this.categoryRecordType = type.RecordType

            this.$bvModal.show('modal-invoice-series')
        },
        onDelete(type) {

            if(type.IsSystem){
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: this.i18nT(`This is the system default invioce series can not be deleted.`),
                        icon: 'AlertTriangleIcon',
                        variant: 'danger'
                    }
                })

            } else {

                this.$swal({
                    title: this.i18nT(`Are you sure?`),
                    text: this.i18nT(`You won't be able to revert this!`),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: this.i18nT(`Yes, delete it!`),
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1'
                    },
                    buttonsStyling: false
                }).then(({ value }) => {
                    if (value) {
                        this.$http
                            .delete(`settings/invoiceSeries?id=${type.Id}`)
                            .then(res => {
                                this.loadData()
                                this.$toast({
                                    component: ToastificationContent,
                                    position: 'top-right',
                                    props: {
                                        title: this.successMessage(res),
                                        icon: 'InfoIcon',
                                        variant: 'success'
                                    }
                                })
                            })
                    }
                })

            }


        },
        loadData() {
            this.$http.get(`settings/invoiceSeries`).then(({ data }) => {
                this.documentTypes = data.data.sort((type1, type2) => {
                    return type1.Pos - type2.Pos
                })
            })

            this.$http.get(
                `system/recordTypes`
            ).then(({ data }) => {
                this.recordTypeOptions = dictToSelectArray(data.data)
                this.recordTypeOptions = this.recordTypeOptions.filter(item => item.value !== '4')
            })
        },
        updateOrder() {
            const formData = new FormData()

            formData.append(
                'list',
                this.documentTypes
                    .filter(documentType => documentType.Id)
                    .map(documentType => documentType.Id)
                    .join(',')
            )

            this.$http
                .post('settings/savePositions', formData)
                .then(res => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.successMessage(res),
                            icon: 'InfoIcon',
                            variant: 'success'
                        }
                    })
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.errorMessage(err),
                            icon: 'AlertTriangleIcon',
                            variant: 'danger'
                        }
                    })
                })
        }
    },
    setup() {
        return {
            required
        }
    },
    created() {
        this.loadData()
    },
}
</script>

<style lang="scss">
.draggable-container {
    display: flex;
    justify-content: space-between;
}
</style>
