<template>
    <b-card>
        <b-row>
            <b-col sm="12">
                <h1>{{ i18nT(`Invoice message templates`) }}</h1>
                <p>{{ i18nT(`You can create different message templates that you will use when sending out your invoices.`) }}</p>
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mb-1 mr-1 pl-1"
                    v-b-modal.modal-invoice-messages
                    @click="resetForm"
                >
                    <feather-icon
                        icon="PlusCircleIcon"
                        class="mr-1"
                    />
                    {{ i18nT(`Add new`) }}
                </b-button>
            </b-col>
        </b-row>
        <!-- MODAL -->
        <b-modal
            id="modal-invoice-messages"
            :title="i18nT(`Message template`)"
            ok-only
            :ok-title="i18nT(`Save`)"
            no-close-on-backdrop
            @ok="validationForm"
        >
            <span>{{ i18nT(`Create and customize automatic message templates.`) }}</span>
            <validation-observer ref="simpleRules">
                <b-form class="mt-2">
                    <b-row>
                        <b-col sm="12">
                            <b-form-group
                                :label="i18nT(`Template name`)"
                                label-for="templateName"
                                class="required"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="Template name"
                                    rules="required"
                                >
                                    <b-form-input
                                        v-model="itemForModal.Label"
                                        :placeholder="i18nT(`E.g. Interview`)"
                                        name="templateName"
                                        :state="errors.length > 0 ? false:null"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <b-col sm="12">
                            <b-form-group
                                :label="i18nT(`Subject`)"
                                label-for="subject"
                                class="required"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="Subject"
                                    rules="required"
                                >
                                    <b-form-input
                                        v-model="itemForModal.Subject"
                                        :placeholder="i18nT(`E.g. 'Your new invoice'`)"
                                        name="subject"
                                        :state="errors.length > 0 ? false:null"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="12">
                            <b-form-group
                                :label="i18nT(`Message`)"
                                label-for="message"
                                class="required"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="Message"
                                    rules="required"
                                >
                                    <b-form-textarea
                                        v-model="itemForModal.Message"
                                        id="textarea-rows"
                                        :placeholder="i18nT(`Welcome to the team...`)"
                                        rows="6"
                                        :state="errors.length > 0 ? false:null"></b-form-textarea>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
            <b-card>
                <h3>{{ i18nT(`Variables`) }}</h3>
                <p>
                    {{ i18nT(`Variables are the placeholders for the dynamic content that will appear in the message.`) }}
                </p>
                <p>
                    <span class="sk-fixed-label">{{ i18nT(`The invoice number`) }}</span>
                    [invoice_number]<br/>
                    <span class="sk-fixed-label">{{ i18nT(`The total payable amount`) }}</span>
                    [invoice_amount]<br/>
                    <span class="sk-fixed-label">{{ i18nT(`The name of your company`) }}</span>
                    [company_name]<br/>
                    <span class="sk-fixed-label">{{ i18nT(`The due date`) }}</span>
                    [invoice_duedate]<br/>
                    <span class="sk-fixed-label">{{ i18nT(`The link for this invoice`) }}</span>
                    [invoice_link]<br/>
                </p>
            </b-card>
        </b-modal>

        <b-table
            hover
            :items="messageTemplates"
            :fields="tableFields"
            show-empty
            :empty-html="emptyHtml"
            ref="refTable"
        >
            <template #cell(IsDefault)="data">
                <font-awesome-icon
                    v-if="data.item.IsDefault"
                    :icon="['fas', 'star']"
                    class="text-primary"
                    v-b-tooltip:hover.hover="i18nT(`Default`)"
                />
                <font-awesome-icon
                    v-else
                    :icon="['far', 'star']"
                    class="text-primary"
                    @click="makeDefault(data)"
                    v-b-tooltip:hover.hover="i18nT(`Make default`)"
                />
            </template>
            <template #cell(Label)="data">
                <h5>
                    {{ data.item.Label }}
                </h5>
            </template>
            <template #cell(Subject)="data">
                {{ data.item.Subject }}
            </template>

            <template #cell(Message)="data">
                {{ data.item.Message | truncate(50) }}
            </template>


            <template #cell(Id)="data">
                <b-dropdown
                    variant="link"
                    toggle-class="p-0"
                    no-caret
                >
                    <template #button-content>
                        <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="align-middle text-body"
                        />
                    </template>
                    <b-dropdown-item
                        v-if="!data.item.IsDefault"
                        @click="makeDefault(data)">
                        <feather-icon icon="StarIcon"/>
                        <span class="align-middle ml-50">{{ i18nT(`Set as default`) }}</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="onEdit(data)">
                        <feather-icon icon="EditIcon"/>
                        <span class="align-middle ml-50">{{ i18nT(`Edit`) }}</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="onDelete(data)">
                        <feather-icon
                            icon="TrashIcon"
                            color="red"
                        />
                        <span
                            class="align-middle ml-50"
                            style="color: red"
                        >{{ i18nT(`Delete`) }}</span>
                    </b-dropdown-item>
                </b-dropdown>
            </template>
        </b-table>
    </b-card>
</template>

<style>
.sk-fixed-label {
    width: 200px;
    display: inline-block;
}
</style>

<script>
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import {
    VBModal,
    VBTooltip,
    BModal,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BTable,
    BDropdown,
    BDropdownItem,
    //BFormSelect,
    BFormTextarea
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {useInputImageRenderer} from '@core/comp-functions/forms/form-utils'
import {ref} from '@vue/composition-api'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    directives: {
        Ripple,
        'b-modal': VBModal,
        'b-tooltip': VBTooltip
    },
    components: {
        BModal,
        BButton,
        BForm,
        BFormGroup,
        BFormInput,
        BRow,
        BCol,
        BCard,
        BTable,
        BDropdown,
        BDropdownItem,
        ValidationProvider,
        ValidationObserver,
        //BFormSelect,
        BFormTextarea
    },
    props: {
        generalData: {
            type: Object,
            default: () => {
            }
        }
    },
    data() {
        return {
            messageTemplates: [],

            editorOption: {
                modules: {
                    toolbar: '#toolbar'
                }
            },
            optionsLocal: [],
            profileFile: null,
            members: [],
            msgTypes: [],
            availableTypes: [],
            tableFields: [
                {
                    key: 'IsDefault',
                    label: '',
                },
                {
                    key: 'Label',
                    label: this.i18nT(`Label`),
                    sortable: true
                },
                {
                    key: 'Subject',
                    label: this.i18nT(`Subject`),
                    sortable: true
                },
                {
                    key: 'Message',
                    label: this.i18nT(`Message`),
                    sortable: true
                },
                {
                    key: 'Id',
                    label: 'Actions'
                }
            ],
            itemForModal: {
                Label: '',
                Type: '',
                Company: null,
                Id: null
            },
            pipelines: [],
        }
    },
    methods: {
        makeDefault(data){
            const formData = new FormData()
            formData.append('id', data.item.Id);

            this.$http
                .post('settings/defaultInvoiceMessage', formData)
                .then(res => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.successMessage(res),
                            icon: 'InfoIcon',
                            variant: 'success'
                        }
                    })
                    this.loadData()
                }).catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.errorMessage(err),
                            icon: 'InfoIcon',
                            variant: 'danger'
                        }
                    })
                })
        },
        resetForm() {
            this.itemForModal = {
                Label: '',
                LastName: '',
                Company: null,
                Id: null
            }
        },
        validationForm(event) {
            event.preventDefault()
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    const formData = new FormData()
                    formData.append('Label', this.itemForModal.Label)
                    formData.append('Subject', this.itemForModal.Subject)
                    formData.append('Message', this.itemForModal.Message)

                    if (this.itemForModal.Id) {
                        formData.append('id', this.itemForModal.Id)
                    }

                    this.$http
                        .post('settings/invoiceMessage', formData)
                        .then(res => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(res),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })
                            this.loadData()

                            this.$bvModal.hide('modal-invoice-messages')
                        })
                        .catch(err => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(err),
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                }
            })
        },
        onDelete({item}) {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, delete it!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(({value}) => {
                if (value) {
                    this.$http
                        .delete(`settings/invoiceMessage?id=${item.Id}`)
                        .then(res => {
                            this.loadData()
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(res),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })
                        })
                }
            })
        },
        onEdit(row) {
            this.itemForModal = Object.assign({}, row.item)
            this.pipelines = [
                ...this.availableTypes
                // this.msgTypes.find(e => e.value === row.item.Type)
            ]
            this.$bvModal.show('modal-invoice-messages')
        },
        loadData() {
            // let msgTypes = []
            this.$http.get(`invoices/invoiceMessages`).then( resp => {
                this.messageTemplates = resp.data.data;
            })
        }
    },
    setup() {
        const refInputEl = ref(null)
        const previewEl = ref(null)
        const refTable = ref(null)
        const {inputImageRenderer} = useInputImageRenderer(refInputEl, previewEl)

        return {
            refInputEl,
            previewEl,
            required,
            refTable,
            inputImageRenderer
        }
    },
    computed: {
        emptyHtml() {
            return "<img src='" + require('@/assets/images/pages/error.svg') + "' alt='Error page' class='img-fluid' width='300'><br/><br/><h4>" + this.i18nT(`No matching records found`) + "</h4>";
        },
    },
    mounted() {
        this.loadData()
    }
}
</script>
